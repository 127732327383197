/**
 * Product Details
 */

 .tm-prodetails {
    &-images {
        width: 100%;
    }

    &-largeimages {
        overflow: hidden;
    }

    &-largeimage {
        a {
            position: relative;
            z-index: 1;
            display: block;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.5);
                z-index: 2;
                pointer-events: none;
                visibility: hidden;
                opacity: 0;
                transition: all 0.3s ease-in-out 0s;
            }

            &::after {
                content: "\e611";
                font-family: 'Themify';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                font-size: 110px;
                z-index: 1;
                color: rgba(255, 255, 255, 0.25);
                pointer-events: none;
                visibility: hidden;
                opacity: 0;
                transition: all 0.3s ease-in-out 0s;
                z-index: 3;
            }

            &:hover {
                &::before {
                    visibility: visible;
                    opacity: 1;
                }

                &::after {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

    }

    &-thumbnails {
        overflow: hidden;

        .slick-list {
            margin-right: -5px;
            margin-left: -5px;
        }

        .tm-slider-arrows-prev,
        .tm-slider-arrows-next {
            background: $color-theme;
            border-color: $color-theme;
            color: #ffffff;
        }

        .tm-slider-arrows-prev {
            left: 0;
            right: auto;
        }

        .tm-slider-arrows-next {
            right: 0;
            left: auto;
        }
    }

    &-thumbnail {
        margin: 0 4px;
        margin-top: 9px;
        cursor: pointer;
        border: 1px solid transparent;
        transition: all 0.3s ease-in-out 0s;
        overflow: hidden;

        &.slick-current {
            border-color: $color-theme;
        }
    }

    &-title {
        margin-top: -5px;
        margin-bottom: 5px;

        a {
            color: $color-heading;

            &:hover {
                color: $color-theme;
            }
        }
    }

    &-availability {
        margin-top: 5px;

        span {
            color: $color-theme;
        }
    }

    &-price {
        margin-top: 15px;
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 700;
        color: $color-heading;

        del {
            font-size: 75%;
            color: #7a7a7a;
            font-weight: 700;
        }
    }

    &-quantitycart {
        margin-top: 30px;
        margin-bottom: 30px;

        .tm-quantitybox {
            margin-right: 20px;
        }
    }

    &-color,
    &-size,
    &-categories,
    &-tags,
    &-share {
        border-top: 1px dashed $color-border;
        padding: 12px 0;

        &>h6 {
            margin-bottom: 0;
            display: inline-block;
            margin-right: 15px;
            vertical-align: middle;
        }

        ul {
            display: inline-block;
            padding-left: 0;
            margin-bottom: 0;

            li {
                list-style: none;
                display: inline-block;

                a {
                    display: inline-block;
                    vertical-align: middle;
                    color: $color-body;

                    &:hover {
                        color: $color-theme;
                    }
                }
            }
        }
    }

    &-color {
        ul {
            font-size: 0;
            line-height: 1;
            vertical-align: middle;

            li {

                &:not(:last-child) {
                    margin-right: 10px;
                }

                span {
                    height: 16px;
                    width: 16px;
                    line-height: 16px;
                    text-align: center;
                    display: inline-block;
                    border-radius: 100px;
                    vertical-align: middle;
                    font-size: 14px;
                    border: 2px solid transparent;
                }

                &.is-checked {
                    span {
                        border-color: $color-theme;
                    }
                }

                &.red {
                    span {
                        background: red;
                    }
                }

                &.green {
                    span {
                        background: green;
                    }
                }

                &.blue {
                    span {
                        background: blue;
                    }
                }

                &.black {
                    span {
                        background: black;
                    }
                }
            }
        }
    }

    &-size {
        ul {
            li {
                span {
                    cursor: pointer;
                }

                &.is-checked {
                    span {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    &-categories,
    &-tags {
        ul {
            li {
                &::after {
                    content: ",";
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    &-share {
        ul {
            li {
                margin-right: 10px;

                a {
                    font-size: 18px;
                }
            }
        }
    }

    &-description {
        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                list-style: none;
            }
        }
    }

    &-review {
        &>h5 {
            margin-bottom: 30px;
        }

        .tm-rating {
            line-height: 1;
            vertical-align: middle;
        }
    }

    @media only screen and (max-width: 1199px) {
        &-thumbnails {

            .tm-slider-arrows-prev,
            .tm-slider-arrows-next {
                top: 50%;
                margin-top: -20px;
            }
        }
    }

    @media #{$layout-mobile-lg} {
        &-content {
            margin-top: 40px;
        }
    }
}

.tm-similliar-products{
    h4{
        margin-bottom: 25px;
    }
}

/* Quantitybox */
.tm-quantitybox {
    position: relative;
    display: inline-block;
    width: 90px;

    input {
        padding-left: 5px;
        padding-right: 30px;
        text-align: center;

        &:focus {
            border-color: $color-border;
        }
    }

    &-button {
        position: absolute;
        right: 0;
        top: 0;
        border: 1px solid $color-border;
        height: 25.5px;
        width: 25px;
        line-height: 23px;
        text-align: center;
        cursor: pointer;

        &.increment-button {
            top: 0;
        }

        &.decrement-button {
            top: auto;
            bottom: 0;
        }
    }

    @media #{$layout-mobile-lg} {
        &-button {
            height: 22.5px;
        }
    }
}


/* Quickview */
.tm-product-quickview {
    padding: 15px 0;
    display: none;

    &.fancybox-content {
        background: transparent;
    }

    .fancybox-close-small {
        padding: 0;
        right: 165px;
        top: 15px;
        height: 30px;
        width: 30px;

        svg {
            path {
                fill: $color-heading;
            }
        }
    }

    &-inner {
        background: #ffffff;
        padding: 20px;
    }

    @media #{$layout-notebook} {
        .fancybox-close-small {
            right: 95px;
        }
    }

    @media #{$layout-tablet} {
        .fancybox-close-small {
            right: 15px;
        }
    }

    @media #{$layout-mobile-lg} {
        .fancybox-close-small {
            right: 15px;
        }
    }
}