/**
 * Heroslider
 */

.tm-heroslider {
    position: relative;
    background-position: right center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex !important;
    align-items: center;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgba(#ffffff, 0.7);
        display: none;
    }

    &-area {
        position: relative;
    }

    &-content {
        padding: 100px 0;

        h1 {
            margin-top: -10px;
            font-size: 48px;
            line-height: 60px;
        }

        p {
            font-size: 20px;
            line-height: 30px;
            margin-top: 30px;
        }

        .tm-button {
            margin-top: 25px;
        }
    }

    @media #{$layout-notebook} {
        &-content {
            h1 {
                margin-top: -8px;
                font-size: 42px;
                line-height: 52px;
            }

            p {
                font-size: 18px;
                line-height: 26px;
                margin-top: 20px;
            }

            .tm-button {
                margin-top: 20px;
            }
        }
    }

    @media #{$layout-tablet} {
        &::before {
            display: block;
        }

        &-content {
            h1 {
                margin-top: -5px;
                font-size: 36px;
                line-height: 44px;
            }

            p {
                font-size: 18px;
                line-height: 26px;
                margin-top: 20px;
            }

            .tm-button {
                margin-top: 20px;
            }
        }
    }

    @media #{$layout-mobile-lg} {

        &::before {
            display: block;
        }

        &-content {
            h1 {
                margin-top: -5px;
                font-size: 36px;
                line-height: 44px;
            }

            p {
                font-size: 18px;
                line-height: 26px;
                margin-top: 15px;
            }

            .tm-button {
                margin-top: 15px;
            }
        }
    }

    @media #{$layout-mobile-sm} {
        background-position: 85% center;

        &-content {
            h1 {
                margin-top: -5px;
                font-size: 32px;
                line-height: 40px;
            }

            p {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
}

.tm-heroslider-slider {
    &.slick-dotted {
        padding-bottom: 0;

        .slick-dots {
            position: absolute;
            left: 0;
            top: auto;
            bottom: 30px;
            z-index: 5;

            li {
                background: $color-body;
                width: 30px;
                height: 3px;
                border-radius: 0;

                &:hover {
                    opacity: 0.5;
                    background: $color-theme;
                }

                &.slick-active {
                    background: $color-theme;
                }
            }
        }
    }



    .tm-heroslider {
        &-contentwrapper {
            overflow: hidden;
        }

        &-content {
            transition: all 1s ease-in-out 0.5s;
            transform: translateX(-100%);
        }

        &.slick-current {
            .tm-heroslider-content {
                transform: translateX(0);
            }
        }
    }

    @media #{$layout-notebook} {
        &.slick-dotted {
            .slick-dots {
                bottom: 20px;
            }
        }
    }

    @media #{$layout-tablet} {
        &.slick-dotted {
            .slick-dots {
                bottom: 20px;
            }
        }
    }
}



.tm-heroslider-social {
    padding-left: 0;
    margin-bottom: 0;
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    width: 35px;
    z-index: 5;
    text-align: center;
    padding: 15px 0;

    &::before {
        content: "";
        position: absolute;
        left: 50%;
        height: 50px;
        width: 1px;
        background: $color-body;
        top: auto;
        bottom: 100%;
    }

    &::after {
        content: "";
        position: absolute;
        left: 50%;
        height: 50px;
        width: 1px;
        background: $color-body;
        top: 100%;
        bottom: auto;
    }

    li {
        list-style: none;
        display: inline-block;
        margin: 7px 0;
        position: relative;

        a {
            color: $color-heading;
            display: inline-block;
            height: 35px;
            width: 35px;
            border-radius: 100px;
            border: 1px solid $color-body;
            font-size: 16px;
            text-align: center;

            i {
                line-height: 35px;
            }

            &:hover {
                background: $color-theme;
                border-color: $color-theme;
                color: #ffffff;
            }
        }

        span {
            position: absolute;
            left: 100%;
            top: 0;
            line-height: 35px;
            visibility: hidden;
            opacity: 0;
            padding-left: 10px;
            font-size: 14px;
        }

        &:hover {
            span {
                visibility: visible;
                opacity: 1;
            }
        }

    }

    @media only screen and (max-width: 1350px) {
        display: none;
    }
}