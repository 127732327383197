/**
 * Header
 */

.tm-header {
    box-shadow: 0 0 5px rgba(#000000, 0.15);
    position: relative;
    z-index: 99;

    &-toparea {
        border-bottom: 1px solid $color-border;
        background: #ffffff;
    }

    &-info {
        padding-left: 0;
        margin-bottom: 0;
        font-size: 0;

        li {
            display: inline-block;
            margin-right: 30px;
            list-style: none;
            font-size: 14px;
            line-height: 28px;
            color: $color-heading;
            vertical-align: middle;

            b {
                font-family: $ff-heading;
            }

            i {
                color: $color-theme;
                margin-right: 5px;
            }

            a {
                color: $color-heading;

                &:hover {
                    color: $color-theme;
                }
            }
        }
    }

    &-icons {
        padding-left: 0;
        margin-bottom: 0;
        text-align: right;
        padding: 10px 0;

        li {
            list-style: none;
            display: inline-block;
            padding: 0 12px;

            &:not(:last-child) {
                border-right: 1px solid $color-border;
            }

            &:first-child {
                display: none;

                a {
                    font-size: 14px;
                }
            }

            a,
            button {
                display: inline-block;
                padding: 0;
                border: 0;
                font-size: 16px;
                color: $color-heading;
                height: auto;
                padding: 3px;

                &:hover {
                    color: $color-theme;
                }
            }
        }
    }

    &-inner {
        padding: 11px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;

        .tm-button {
            padding: 0 15px;
        }
    }

    &-logo {
        margin-right: 20px;

        img {
            max-height: 100%;
        }
    }

    &-nav {
        flex-grow: 1;
        text-align: right;
    }

    &-button {
        margin-left: 40px;
    }

    &-bottomarea {
        background: #ffffff;
    }

    &-bottominside {
        position: relative;
    }

    &-searcharea {
        padding: 15px 0;
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: #ffffff;
        z-index: 101;
        transition: $transition;
        transform: scaleX(0);

        form {
            position: relative;
            flex-grow: 100;

            input {
                border: 1px solid darken($color-border, 10);
                border-radius: 100px;
                padding-left: 30px;
                padding-right: 50px;
                background: rgba($color-grey, 0.2);
            }

            button,
            input[type="submit"] {
                position: absolute;
                top: 0;
                left: auto;
                right: 0;
                height: 50px;
                width: 50px;
                text-align: center;
                line-height: 50px;
                border: 0;

                &:hover {
                    color: $color-theme;
                }
            }
        }

        &.is-visible {
            transform: scaleX(1);
        }
    }

    &-searchclose {
        border: 0;
        color: $color-theme;
    }

    @media #{$layout-tablet} {
        &-button {
            margin-right: 50px;
        }
    }

    @media #{$layout-mobile-lg} {
        &-info {
            text-align: center;
            padding: 10px 0;
            border-bottom: 1px solid $color-border;

            li {
                margin: 0 15px;
            }
        }

        &-icons {
            text-align: center;

            li:first-child {
                display: inline;
            }

            li {
                padding: 0 9px;
            }
        }

        &-logo {
            height: 53px;
            display: inline-block;
        }

        &-button {
            display: none;
        }

        &-searcharea {
            form {

                button,
                input[type="submit"] {
                    height: 45px;
                    line-height: 45px;
                }
            }
        }
    }
}

.tm-header-sticky {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;

    &.is-sticky {
        .tm-header-bottomarea {
            position: fixed;
            width: 100%;
            left: 0;
            right: 0;
            top: 0;
            animation: slideInDown 0.5s ease-in-out 0s 1 both;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            z-index: 100;
        }
    }
}



/* Mobilemenu */
.tm-mobilenav {
    position: absolute;
    top: 100%;
    width: 100%;

    .mean-bar {
        background: #0c1923;
        padding: 0;
        min-height: 0;
        z-index: 99;
    }

    a.meanmenu-reveal {
        margin-top: -53px;
        text-indent: 0;
        padding: 0;
        text-align: center;
        width: 25px;
        height: 25px;
        line-height: 20px;
        color: #3c424f;
        font-size: 24px !important;
    }

    .mean-nav {
        margin-top: 0;
        background: $color-theme;
        position: absolute;
        left: 0;
        top: 100%;
        right: 0;
        width: 100%;
        box-shadow: $shadow;

        &>ul {
            max-height: 201px;
            overflow-y: auto;

            li {
                a {
                    padding: 7px 5%;
                    border-top: 1px solid rgba(255, 255, 255, .2);
                    font-size: 14px;
                    font-family: $ff-heading;
                    text-transform: inherit;

                    &:hover {
                        background: #ffffff;
                        color: $color-theme;
                    }

                    &.mean-expand {
                        padding: 0 6px !important;
                        height: 39px;
                        line-height: 39px;
                        background: rgba($color-theme, 0.7);
                        color: #ffffff;
                        margin-top: 0;

                        &:hover {
                            background: rgba(255, 255, 255, .1);
                        }
                    }
                }

                li {
                    a {
                        opacity: 1;
                    }
                }

                ul {
                    li {
                        a {
                            padding: 7px 10%;
                        }
                    }

                    ul {
                        li {
                            a {
                                padding: 7px 15%;
                            }
                        }

                        ul {
                            li {
                                a {
                                    padding: 7px 20%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media #{$layout-mobile-lg} {
        a.meanmenu-reveal {
            margin-top: -50px;
        }
    }
}