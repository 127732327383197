/**
 * Button
 */

.tm-button,
a.tm-button,
button.tm-button {
    display: inline-block;
    height: 50px;
    min-width: 150px;
    background: $color-theme;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    font-weight: 600;
    border-radius: 4px;
    z-index: 1;
    position: relative;
    overflow: hidden;
    padding: 0 40px;
    vertical-align: middle;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: -100%;
        height: 100%;
        width: 100%;
        background: $color-heading;
        z-index: -1;
        transition: all 0.4s ease-in-out 0s;
    }

    &:hover {
        &::before {
            top: 0;
        }
    }

    &.tm-button-dark {
        background: $color-heading;

        &::before {
            background: $color-theme;
        }
    }

    &.tm-button-white {
        background: #ffffff;
        color: $color-heading;

        &::before {
            background: $color-theme;
        }

        &:hover {
            color: #ffffff;
        }
    }

    &.tm-button-transparent {
        background: transparent;
        color: $color-theme;
        border: 1px solid $color-theme;

        &::before {
            background: $color-theme;
        }

        &:hover {
            color: #ffffff;
        }

        &.tm-button-white {
            border-color: #ffffff;
            color: #ffffff;

            &::before {
                background: #ffffff;
            }

            &:hover {
                color: $color-heading;
            }
        }

        &.tm-button-dark {
            border-color: $color-heading;
            color: $color-heading;

            &::before {
                background: $color-heading;
            }

            &:hover {
                color: #ffffff;
            }
        }
    }

    &.tm-button-small {
        height: 45px;
        line-height: 45px;
        min-width: 100px;
        padding: 0 25px;
    }

    @media #{$layout-mobile-lg} {
        height: 45px;
        line-height: 45px;
        min-width: 100px;
        padding: 0 25px;
    }
}


/* Readmore */
.tm-readmore,
a.tm-readmore,
button.tm-readmore {
    @extend .tm-button;

    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    min-width: 100px;
    font-size: 14px;
    border: 1px solid $color-theme;
    background: transparent;
    color: $color-theme;

    &::before {
        background: $color-theme;
    }

    &:hover {
        color: #ffffff;
    }

    &.tm-readmore-dark {
        border-color: $color-heading;
        color: $color-heading;

        &::before {
            background: $color-theme;
        }

        &:hover {
            color: #ffffff;
            border-color: $color-theme;
        }
    }

    &.tm-readmore-white {
        border-color: #ffffff;
        color: #ffffff;

        &::before {
            background: #ffffff;
        }

        &:hover {
            color: $color-heading;
            border-color: #ffffff;
        }
    }
}


/* Video button */
.tm-videobutton,
a.tm-videobutton,
button.tm-videobutton {
    display: inline-block;
    height: 130px;
    width: 130px;
    padding: 0;
    border: none;
    outline: none;
    background: rgba($color-theme, 0.3);
    border-radius: 1000px;
    font-size: 130px;
    color: #ffffff;
    transition: $transition;

    i {
        vertical-align: middle;
        margin-top: -16px;
        display: inline-block;
    }

    &:hover {
        background: $color-theme;
    }

    @media #{$layout-tablet} {
        height: 100px;
        width: 100px;
        font-size: 100px;

        i {
            margin-top: -12px;
        }
    }

    @media #{$layout-mobile-lg} {
        height: 100px;
        width: 100px;
        font-size: 100px;

        i {
            margin-top: -12px;
        }
    }
}