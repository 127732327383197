/**
 * Product
 */

.tm-product {
    &-topside {
        position: relative;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    &-actions {
        padding-left: 0;
        margin-bottom: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: auto;
        bottom: 0;
        width: 100%;
        background: transparent;
        padding: 10px 20px;
        text-align: center;
        font-size: 0;
        z-index: 1;

        &::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            background: rgba($color-theme, 0.8);
            transition: $transition;
            transform: translateY(100%);
            z-index: -1;
        }

        li {
            list-style: none;
            display: inline-block;
            font-size: 18px;
            vertical-align: middle;
            visibility: hidden;
            opacity: 0;
            transform: translateY(100%);
            transition: $transition;

            &:not(:last-child) {
                border-right: 1px solid rgba(#ffffff, 0.4);
            }

            a,
            button {
                display: inline-block;
                vertical-align: middle;
                padding: 8px 20px;
                color: #ffffff;
                outline: none;
                border: none;
                height: auto;
            }
        }
    }

    &-bottomside {
        padding-top: 25px;

        h6 {
            margin-bottom: 8px;

            a {
                color: $color-heading;

                &:hover {
                    color: $color-theme;
                }
            }
        }

        .tm-ratingbox {
            margin-top: 5px;
        }
    }

    &-price {
        del {
            margin-right: 10px;
        }
    }

    &:hover &-actions {
        &::before {
            transform: translateY(0);
        }

        li {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.tm-ratingbox {
    font-size: 0;

    span {
        font-size: 18px;
        display: inline-block;
        vertical-align: middle;

        &:not(:last-child) {
            margin-right: 4px;
        }

        &.is-active {
            color: $color-theme;
        }
    }
}