/**
 * Title
 */

.tm-sectiontitle {
    margin-bottom: 55px;
    
    h2 {
        margin-top: -8px;
        margin-bottom: 12px;
    }

    &-divider {
        display: inline-block;
        max-width: 16px;
        position: relative;
        line-height: 1;
        margin-bottom: 20px;

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 70px;
            height: 2px;
            background: $color-heading;
            top: 50%;
        }

        &::before {
            left: auto;
            right: calc(100% + 10px);
        }

        &::after {
            left: calc(100% + 10px);
            right: auto;
        }

        img {
            width: 100%;
        }
    }

    p {
        margin-bottom: 0;
    }
}