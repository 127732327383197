/**
 * Selectbox
 */

.nice-select {
    height: 50px;
    border-radius: 3px;
    line-height: 48px;
    padding: 0 15px;
    padding-right: 35px;
    width: 100%;
    border-color: $color-border;

    &::after {
        content: "\e64b";
        font-family: 'Themify';
        font-size: 10px;
        position: absolute;
        border: 0;
        transform: rotate(0deg);
        transform-origin: center center;
        height: 9px;
        width: 9px;
        line-height: 1;
        text-align: center;
    }

    .current {
        display: block;
        padding: 0;
    }

    .list {
        border-radius: 0;
        margin-bottom: 0;
        min-width: 180px;
        width: 100%;
        margin-top: 1px;

        .option {
            padding: 0 15px;
            min-height: auto;
            line-height: 30px;

            &:hover {
                background: $color-theme;
                color: #ffffff;
            }
        }
    }

    &:focus {
        border-color: $color-theme;
    }

    &.open {
        border-color: $color-theme;

        &:after {
            transform: rotate(180deg);
        }
    }

    @media #{$layout-mobile-lg} {
        height: 44px;
        line-height: 42px;
    }
}