/**
 * Subscribe
 */

.tm-subscribe {
    text-align: center;

    h3 {
        margin-top: -7px;
    }

    &-form {
        position: relative;
        margin: 0 100px;
        margin-top: 35px;

        input:not([type="submit"]) {
            background: #ffffff;
            border: 0px;
            height: 60px;
            line-height: 60px;
            border-radius: 4px;
            padding: 0 30px;
            padding-right: 85px;
        }

        button,
        input[type="submit"] {
            position: absolute;
            right: 5px;
            top: 5px;
            min-width: auto;
            font-size: 32px;
            padding: 0 20px;

            i {
                &::before {
                    margin-top: -7px;
                    display: inline-block;
                }
            }
        }
    }

    &-social {
        padding-left: 0;
        margin-bottom: 0;
        font-size: 0;
        margin-top: 30px;

        li {
            list-style: none;
            display: inline-block;
            margin: 0 15px;
            font-size: 24px;
            vertical-align: middle;

            a {
                display: inline-block;
                font-size: 24px;
                color: $color-heading;

                &:hover {
                    color: $color-theme;
                }
            }
        }
    }

    @media #{$layout-tablet} {
        &-form {
            margin: 0 50px;
            margin-top: 45px;
        }
    }

    @media #{$layout-mobile-lg} {
        &-form {
            margin: 0 50px;
            margin-top: 35px;
        }

        &-social {
            li {
                margin: 0 10px;

                a {
                    font-size: 20px;
                }
            }
        }
    }

    @media #{$layout-tablet} {
        &-form {
            input:not([type="submit"]) {
                padding-left: 15px;
            }
        }
    }

    @media #{$layout-mobile-lg} {
        &-form {
            margin-left: 0;
            margin-right: 0;

            input:not([type="submit"]) {
                padding-left: 15px;
                height: 55px;
                line-height: 55px;
            }
        }
    }
}