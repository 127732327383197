/**
 * Contact
 */

.tm-contact {

    &-area {
        background-size: inherit;
        background-position: left top;
    }

    &-top {
        padding: 50px 0;
        box-shadow: $shadow;
        background: #ffffff;

        .row {
            [class*="col-"]:not(:last-child) {
                border-right: 1px solid $color-border;
            }
        }
    }

    &-address {
        padding: 0 70px;

        h4 {
            color: $color-theme;
            margin-bottom: 20px;
        }
    }

    &-addressblock {
        position: relative;
        padding-left: 100px;

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        b {
            position: absolute;
            left: 0;
            top: 0;
        }

        p {
            margin-bottom: 0;
        }

        a {
            color: $color-body;

            &:hover {
                color: $color-theme;
            }
        }
    }

    &-formwrapper {
        h3 {
            margin-top: -7px;
            margin-bottom: 30px;
        }
    }
    
    &-map {
        h3 {
            margin-top: -7px;
            margin-bottom: 30px;
        }
    }

    @media #{$layout-notebook} {
        &-top {
            padding: 30px 0;
        }

        &-address {
            padding: 0 30px;
        }
    }

    @media #{$layout-tablet} {
        &-top {
            padding: 0 50px;

            .row {
                [class*="col-"]:not(:last-child) {
                    border-right: 0;
                    border-bottom: 1px solid $color-border;
                }
            }
        }

        &-address {
            padding: 50px 0;
        }
    }

    @media #{$layout-mobile-lg} {
        &-top {
            padding: 0 30px;

            .row {
                [class*="col-"]:not(:last-child) {
                    border-right: 0;
                    border-bottom: 1px solid $color-border;
                }
            }
        }

        &-address {
            padding: 30px 0;
        }
    }

    @media #{$layout-mobile-sm} {
        &-top {
            padding: 0 15px;
        }

        &-addressblock {
            padding-left: 75px;
        }
    }
}

.google-map {
    min-height: 400px;

    @media #{$layout-tablet} {
        min-height: 350px;
    }

    @media #{$layout-mobile-lg} {
        min-height: 350px;
    }

    @media #{$layout-mobile-sm} {
        min-height: 300px;
    }
}