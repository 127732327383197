/*
 * %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
 *
 * Template Name: Munu - Beauty HTML5 Template   
 * Template URI: https://thememarch.com/demo/html/munu/
 * Description: Munu is a fully responsive beauty HTML template which comes with the unique and clean design. It built with latest bootstrap 4 framework which makes the template fully customizable. It has also e-commerce support. E-commerce pages are included on this template.
 * Author: Thememarch
 * Author URI: https://thememarch.com
 * Version: 1.2
 *
 * %%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
 */
/*
=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
    TABLE OF CONTENTS
=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>

    01. Reset 
    02. Animations 
    03. Typography 
    04. Utilities 
    
    05. Button
    06. Title
    07. Feature
    08. Form
    09. Selectbox
    10. Tabgroup
    11. Accordion
    12. Pagination

    13. Header
    14. Navigation
    15. Heroslider
    16. About
    17. Video Area
    18. Service
    19. Pricing & Compare
    20. Appointment
    21. Product
    22. Offer
    23. Testimonial
    24. Subscribe
    25. Team Member
    26. Portfolio
    27. Call to action
    28. Blog
    29. Contact
    30. Brand Logo
    31. Instagram
    32. Shop
    33. Breadcrumb
    34. Widgets
    35. Comment
    36. Footer

    37. Product Details
    38. Shopping Cart
    39. Checkout
    40. My Account

=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
    END TABLE OF CONTENTS
=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>=>
*/
/**
 * Reset
 */
@import url("https://fonts.googleapis.com/css?family=PT+Serif:400,400i,700,700i");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700");
html {
  color: #222;
  font-size: 1em;
  line-height: 1.4;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.hidden {
  display: none !important;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

@media only screen and (min-width: 35em) {
  /* Style adjustments for viewports that meet the condition */
}

@media print, (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 1.25dppx), (min-resolution: 120dpi) {
  /* Style adjustments for high resolution devices */
}

@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: #000 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: "";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

/**
 * Animations
 */
@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes tmFillFull {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

/**
 * Typography
 */
* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

html {
  overflow: hidden;
  overflow-y: auto;
}

body {
  overflow: hidden !important;
  font-size: 16px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans", sans-serif;
  color: #686b77;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  body {
    font-size: 14px;
    line-height: 23px;
  }
}

::-webkit-input-placeholder {
  color: #686b77;
}

:-moz-placeholder {
  color: #686b77;
}

::-moz-placeholder {
  color: #686b77;
}

:-ms-input-placeholder {
  color: #686b77;
}

h1,
h2,
h3,
h4,
h5,
h6,
address,
p,
pre,
blockquote,
dl,
dd,
menu,
ol,
ul,
table,
caption,
hr {
  margin: 0;
  margin-bottom: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "PT Serif", serif;
  color: #3c424f;
}

h1,
.h1 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 700;
}

h2,
.h2 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 700;
}

h3,
.h3 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
}

h4,
.h4 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}

h5,
.h5 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

h6,
.h6 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1,
  .h1 {
    font-size: 34px;
    line-height: 44px;
  }
  h2,
  .h2 {
    font-size: 32px;
    line-height: 40px;
  }
  h3,
  .h3 {
    font-size: 28px;
    line-height: 38px;
  }
}

@media only screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 32px;
    line-height: 42px;
  }
  h2,
  .h2 {
    font-size: 30px;
    line-height: 38px;
  }
  h3,
  .h3 {
    font-size: 26px;
    line-height: 36px;
  }
  h4,
  .h4 {
    font-size: 22px;
    line-height: 32px;
  }
  h5,
  .h5 {
    font-size: 18px;
    line-height: 28px;
  }
  h6,
  .h6 {
    font-size: 16px;
    line-height: 24px;
  }
}

::-moz-selection {
  background: #ff6c8d;
  color: #ffffff;
}

::-ms-selection {
  background: #ff6c8d;
  color: #ffffff;
}

::-o-selection {
  background: #ff6c8d;
  color: #ffffff;
}

::selection {
  background: #ff6c8d;
  color: #ffffff;
}

a {
  transition: all 0.3s ease-in-out 0s;
}

a:hover, a:focus, a:active {
  text-decoration: none;
  outline: none;
}

a:visited {
  color: inherit;
}

button {
  cursor: pointer;
}

input,
button,
select,
textarea {
  background: transparent;
  border: 1px solid #ebebeb;
  transition: all 0.4s ease-out 0s;
  color: #686b77;
  font-family: "Open Sans", sans-serif;
}

input::-webkit-input-placeholder,
button::-webkit-input-placeholder,
select::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #686b77;
}

input:-moz-placeholder,
button:-moz-placeholder,
select:-moz-placeholder,
textarea:-moz-placeholder {
  color: #686b77;
}

input::-moz-placeholder,
button::-moz-placeholder,
select::-moz-placeholder,
textarea::-moz-placeholder {
  color: #686b77;
}

input:-ms-input-placeholder,
button:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #686b77;
}

input:focus, input:active,
button:focus,
button:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
  outline: none;
  border-color: #ff6c8d;
}

input,
select,
textarea {
  width: 100%;
  font-size: 14px;
  vertical-align: middle;
}

input,
select,
button {
  height: 50px;
  padding: 0 15px;
}

@media only screen and (max-width: 767px) {
  input,
  select,
  button {
    height: 44px;
  }
}

textarea {
  padding: 10px 15px;
}

blockquote {
  border-left: 5px solid #ff6c8d;
  font-size: 18px;
  line-height: 27px;
  padding: 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

blockquote::after {
  content: "\e67e";
  font-family: "Themify";
  font-weight: 700;
  position: absolute;
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
  z-index: -1;
  font-size: 120px;
  line-height: 0.5;
  color: #f2f2f2;
}

blockquote p {
  margin-bottom: 10px;
  font-style: italic;
}

blockquote footer {
  font-size: 14px;
}

blockquote footer a {
  color: #ff6c8d;
}

blockquote footer a:hover {
  color: #ff6c8d;
}

@media only screen and (max-width: 767px) {
  blockquote {
    font-size: 16px;
    line-height: 25px;
    padding: 20px;
  }
}

[class^="flaticon-"],
[class*=" flaticon-"],
[class^="flaticon-"],
[class*=" flaticon-"] {
  line-height: 1;
}

[class^="flaticon-"]::before, [class^="flaticon-"]::after,
[class*=" flaticon-"]::before,
[class*=" flaticon-"]::after,
[class^="flaticon-"]::before,
[class^="flaticon-"]::after,
[class*=" flaticon-"]::before,
[class*=" flaticon-"]::after {
  margin-left: 0;
  font-size: inherit;
  vertical-align: middle;
}

.stylish-list, .stylish-list-color {
  padding-left: 0;
  display: block;
}

.stylish-list li, .stylish-list-color li {
  list-style: none;
  line-height: 30px;
  position: relative;
  padding-left: 30px;
}

.stylish-list li i, .stylish-list-color li i {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 16px;
  line-height: 30px;
  color: #686b77;
}

.stylish-list-color li i {
  color: #ff6c8d;
}

/* Checkbox & Radio Styles */
input[type=checkbox],
input[type=radio] {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
  display: none;
}

input[type=checkbox] ~ label,
input[type=radio] ~ label {
  position: relative;
  padding-left: 24px;
  cursor: pointer;
}

input[type=checkbox] ~ label a,
input[type=radio] ~ label a {
  font-weight: 600;
}

input[type=checkbox] ~ label:before,
input[type=radio] ~ label:before {
  content: "";
  font-family: 'Themify';
  font-weight: 700;
  position: absolute;
  left: 0;
  top: 3px;
  border: 1px solid #dddddd;
  height: 15px;
  width: 15px;
  line-height: 1.5;
  font-size: 9px;
  text-align: center;
}

input[type=checkbox]:checked ~ label,
input[type=radio]:checked ~ label {
  color: #ff6c8d;
}

input[type=checkbox]:checked ~ label a,
input[type=radio]:checked ~ label a {
  color: #ff6c8d;
}

input[type=checkbox]:checked ~ label:before,
input[type=radio]:checked ~ label:before {
  content: "\e64c";
  color: #ff6c8d;
  border-color: #ff6c8d;
}

input[type=radio] ~ label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border: 2px solid #dddddd;
  border-radius: 100px;
  height: 15px;
  width: 15px;
  margin-top: -8px;
  line-height: .5;
  font-size: 28px;
}

input[type=radio]:checked ~ label::before {
  content: "";
  color: #ff6c8d;
  border-color: #ff6c8d;
}

/**
 * Utilities
 */
.hidden {
  display: none;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* Define Colors */
.color-theme {
  color: #ff6c8d;
}

.color-secondary {
  color: #3498db;
}

.color-dark {
  color: #202020;
}

.colog-grey {
  color: #fdf4f3;
}

.color-black {
  color: #000000;
}

.color-white {
  color: #ffffff;
}

/* Background Color */
.bg-theme {
  background-color: #ff6c8d;
}

.bg-secondary {
  background-color: #3498db !important;
}

.bg-grey {
  background-color: #fdf4f3;
}

.bg-dark {
  background-color: #202020 !important;
}

.bg-black {
  background-color: #000000;
}

.bg-white {
  background-color: #ffffff !important;
}

.bg-gradient {
  background-image: linear-gradient(to bottom, #ffffff 0%, #fdf4f3 100%);
}

/* Background Image */
[class*="bg-image-"],
[data-bgimage] {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bg-image-1 {
  background-image: url(../images/bg/bg-image-1.jpg);
}

.bg-image-2 {
  background-image: url(../images/bg/bg-image-2.jpg);
}

.bg-image-3 {
  background-image: url(../images/bg/bg-image-3.jpg);
}

.bg-image-4 {
  background-image: url(../images/bg/bg-image-4.jpg);
}

.bg-image-5 {
  background-image: url(../images/bg/bg-image-5.jpg);
}

.bg-image-6 {
  background-image: url(../images/bg/bg-image-6.jpg);
}

.bg-image-7 {
  background-image: url(../images/bg/bg-image-7.jpg);
}

.bg-image-8 {
  background-image: url(../images/bg/bg-image-8.jpg);
}

.bg-image-9 {
  background-image: url(../images/bg/bg-image-9.jpg);
}

.bg-image-10 {
  background-image: url(../images/bg/bg-image-10.jpg);
}

.bg-image-11 {
  background-image: url(../images/bg/bg-image-11.jpg);
}

.bg-image-12 {
  background-image: url(../images/bg/bg-image-12.jpg);
}

.bg-image-13 {
  background-image: url(../images/bg/bg-image-13.jpg);
}

.bg-image-14 {
  background-image: url(../images/bg/bg-image-14.jpg);
}

.bg-image-15 {
  background-image: url(../images/bg/bg-image-15.jpg);
}

.bg-image-16 {
  background-image: url(../images/bg/bg-image-16.jpg);
}

.bg-image-17 {
  background-image: url(../images/bg/bg-image-17.jpg);
}

.bg-image-18 {
  background-image: url(../images/bg/bg-image-18.jpg);
}

.bg-image-19 {
  background-image: url(../images/bg/bg-image-19.jpg);
}

.bg-image-20 {
  background-image: url(../images/bg/bg-image-20.jpg);
}

/* Text specialized */
.text-italic {
  font-style: italic;
}

.text-normal {
  font-style: normal;
}

.text-underline {
  font-style: underline;
}

/* Font specialized */
.body-font {
  font-family: "Open Sans", sans-serif;
}

.heading-font {
  font-family: "PT Serif", serif;
}

/* Height and width */
.tm-fullscreen {
  min-height: 100vh;
  width: 100%;
}

/* Overlay styles */
[data-overlay],
[data-secondary-overlay],
[data-black-overlay],
[data-white-overlay] {
  position: relative;
}

[data-overlay] > div,
[data-overlay] > *,
[data-secondary-overlay] > div,
[data-secondary-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > *,
[data-white-overlay] > div,
[data-white-overlay] > * {
  position: relative;
  z-index: 2;
}

[data-overlay]:before,
[data-secondary-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

[data-overlay]:before {
  background-color: #ff6c8d;
}

[data-secondary-overlay]:before {
  background-color: #ff6c8d;
}

[data-black-overlay]:before {
  background-color: #000000;
}

[data-white-overlay]:before {
  background-color: #ffffff;
}

[data-overlay="0.5"]:before,
[data-secondary-overlay="0.5"]:before,
[data-black-overlay="0.5"]:before,
[data-white-overlay="0.5"]:before {
  opacity: 0.05;
}

[data-overlay="1"]:before,
[data-secondary-overlay="1"]:before,
[data-black-overlay="1"]:before,
[data-white-overlay="1"]:before {
  opacity: 0.1;
}

[data-overlay="1.5"]:before,
[data-secondary-overlay="1.5"]:before,
[data-black-overlay="1.5"]:before,
[data-white-overlay="1.5"]:before {
  opacity: 0.15;
}

[data-overlay="2"]:before,
[data-secondary-overlay="2"]:before,
[data-black-overlay="2"]:before,
[data-white-overlay="2"]:before {
  opacity: 0.2;
}

[data-overlay="2.5"]:before,
[data-secondary-overlay="2.5"]:before,
[data-black-overlay="2.5"]:before,
[data-white-overlay="2.5"]:before {
  opacity: 0.25;
}

[data-overlay="3"]:before,
[data-secondary-overlay="3"]:before,
[data-black-overlay="3"]:before,
[data-white-overlay="3"]:before {
  opacity: 0.3;
}

[data-overlay="3.5"]:before,
[data-secondary-overlay="3.5"]:before,
[data-black-overlay="3.5"]:before,
[data-white-overlay="3.5"]:before {
  opacity: 0.35;
}

[data-overlay="4"]:before,
[data-secondary-overlay="4"]:before,
[data-black-overlay="4"]:before,
[data-white-overlay="4"]:before {
  opacity: 0.4;
}

[data-overlay="4.5"]:before,
[data-secondary-overlay="4.5"]:before,
[data-black-overlay="4.5"]:before,
[data-white-overlay="4.5"]:before {
  opacity: 0.45;
}

[data-overlay="5"]:before,
[data-secondary-overlay="5"]:before,
[data-black-overlay="5"]:before,
[data-white-overlay="5"]:before {
  opacity: 0.5;
}

[data-overlay="5.5"]:before,
[data-secondary-overlay="5.5"]:before,
[data-black-overlay="5.5"]:before,
[data-white-overlay="5.5"]:before {
  opacity: 0.55;
}

[data-overlay="6"]:before,
[data-secondary-overlay="6"]:before,
[data-black-overlay="6"]:before,
[data-white-overlay="6"]:before {
  opacity: 0.6;
}

[data-overlay="6.5"]:before,
[data-secondary-overlay="6.5"]:before,
[data-black-overlay="6.5"]:before,
[data-white-overlay="6.5"]:before {
  opacity: 0.65;
}

[data-overlay="7"]:before,
[data-secondary-overlay="7"]:before,
[data-black-overlay="7"]:before,
[data-white-overlay="7"]:before {
  opacity: 0.7;
}

[data-overlay="7.5"]:before,
[data-secondary-overlay="7.5"]:before,
[data-black-overlay="7.5"]:before,
[data-white-overlay="7.5"]:before {
  opacity: 0.75;
}

[data-overlay="8"]:before,
[data-secondary-overlay="8"]:before,
[data-black-overlay="8"]:before,
[data-white-overlay="8"]:before {
  opacity: 0.8;
}

[data-overlay="8.5"]:before,
[data-secondary-overlay="8.5"]:before,
[data-black-overlay="8.5"]:before,
[data-white-overlay="8.5"]:before {
  opacity: 0.85;
}

[data-overlay="9"]:before,
[data-secondary-overlay="9"]:before,
[data-black-overlay="9"]:before,
[data-white-overlay="9"]:before {
  opacity: 0.9;
}

[data-overlay="9.5"]:before,
[data-secondary-overlay="9.5"]:before,
[data-black-overlay="9.5"]:before,
[data-white-overlay="9.5"]:before {
  opacity: 0.95;
}

[data-overlay="10"]:before,
[data-secondary-overlay="10"]:before,
[data-black-overlay="10"]:before,
[data-white-overlay="10"]:before {
  opacity: 1;
}

/* Slick Preset */
.slick-slide:active, .slick-slide:focus {
  outline: none;
}

.slick-slide.slick-current:active, .slick-slide.slick-current:focus, .slick-slide.slick-active:active, .slick-slide.slick-active:focus {
  outline: none;
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
  padding-bottom: 50px;
}

/* Section Padding */
.tm-padding-section {
  padding-top: 100px;
  padding-bottom: 100px;
}

.tm-padding-section-top {
  padding-top: 100px;
}

.tm-padding-section-bottom {
  padding-bottom: 100px;
}

.tm-padding-section-sm {
  padding-top: 60px;
  padding-bottom: 60px;
}

.tm-padding-section-sm-top {
  padding-top: 60px;
}

.tm-padding-section-sm-bottom {
  padding-bottom: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-padding-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .tm-padding-section-top {
    padding-top: 80px;
  }
  .tm-padding-section-bottom {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-padding-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .tm-padding-section-top {
    padding-top: 70px;
  }
  .tm-padding-section-bottom {
    padding-bottom: 70px;
  }
}

.mt-30-reverse {
  margin-top: -30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mtb-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.ptb-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.mt-40-reverse {
  margin-top: -40px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mtb-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.ptb-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mt-50-reverse {
  margin-top: -50px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mtb-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.ptb-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

#back-top-top {
  position: fixed;
  left: auto;
  right: 30px;
  bottom: 30px;
  height: 45px;
  width: 45px;
  padding: 0;
  line-height: 1;
  text-align: center;
  background: #ff6c8d;
  color: #ffffff;
  font-size: 14px;
  border-radius: 100%;
  border: 1px solid #ff6c8d;
  animation: slideInRight 0.4s ease-in-out 1 both;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

#back-top-top i {
  line-height: 45px;
}

@media only screen and (max-width: 767px) {
  #back-top-top {
    display: none;
  }
}

.form-messages,
.appointment-messages {
  margin-bottom: 0;
  text-align: center;
}

.form-messages.error,
.appointment-messages.error {
  margin-top: 20px;
  color: #f80707;
}

.form-messages.success,
.appointment-messages.success {
  margin-top: 20px;
  color: #0d8d2d;
}

.tm-parallax {
  position: relative;
  z-index: 0;
  background-size: cover;
}

.tm-parallax > .tm-parallax-img {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* Youtube Video Background */
.tm-videobgholder {
  position: relative;
}

.tm-videobgwarpper {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0 !important;
  left: 0;
  z-index: -1;
}

.tm-videobg {
  display: block;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-position: center center;
}

.tm-video-controls {
  position: absolute;
  left: auto;
  right: 30px;
  top: auto;
  bottom: 30px;
  z-index: 1;
}

.tm-video-controls button {
  height: 45px;
  width: 45px;
  color: #777777;
  border: 2px solid #777777;
  border-radius: 1000px;
  display: inline-block;
  text-align: center;
  line-height: 43px;
  font-size: 22px;
}

.tm-video-controls button:hover {
  color: #ffffff;
  border-color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-video-controls {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .tm-video-controls {
    display: none;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 575px) {
  .container {
    max-width: 440px;
  }
}

.lg-backdrop {
  background-color: rgba(25, 25, 25, 0.92);
}

.tm-rating span {
  color: #b7b9c1;
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
}

.tm-rating span.active {
  color: #fdc716;
}

/* Preloader Styels */
.tm-preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: #272635;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transform-origin: 0 0;
  transition: all 0.6s ease-out 0s;
}

.tm-preloader-logo {
  text-align: center;
}

.tm-preloader-progress {
  display: block;
  height: 3px;
  width: 100%;
  background: #77768e;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}

.tm-preloader-progress::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #ff6c8d;
  transform-origin: 0 0;
  animation: tmFillFull 4s linear 0s 1 both;
}

.tm-preloader .tm-button, .tm-preloader .tm-readmore {
  position: absolute;
  left: auto;
  top: auto;
  right: 15px;
  bottom: 15px;
  border: 0;
}

.tm-section {
  position: relative;
  z-index: 1;
}

.compensate-for-scrollbar {
  margin-right: 0 !important;
}

.tm-autoflex {
  justify-content: space-between;
}

.tm-autoflex .col {
  flex-basis: auto;
  flex-grow: inherit;
  width: auto;
}

.slick-slider.slick-dotted {
  padding-bottom: 60px;
}

.slick-slider.slick-dotted .slick-dots {
  bottom: 0;
  line-height: 1;
}

.slick-slider.slick-dotted .slick-dots li {
  display: inline-block;
  height: 12px;
  width: 12px;
  background: #3c424f;
  border-radius: 100px;
  transition: all 0.4s ease-in-out;
  margin: 0 7px;
}

.slick-slider.slick-dotted .slick-dots li button {
  display: none;
}

.slick-slider.slick-dotted .slick-dots li.slick-active {
  background: #ff6c8d;
}

.slick-slider .slick-arrow {
  z-index: 1;
  height: 45px;
  width: 45px;
  display: inline-block;
  background: rgba(255, 108, 141, 0.6);
  text-align: center;
  color: #ffffff;
  font-size: 18px;
}

.slick-slider .slick-arrow::before {
  display: none;
}

.slick-slider .slick-arrow:hover {
  background: #ff6c8d;
}

.slick-slider .slick-arrow.slick-prev {
  left: 0;
}

.slick-slider .slick-arrow.slick-next {
  right: 0;
}

@media only screen and (max-width: 767px) {
  .fancybox-slide {
    padding: 15px;
  }
}

.tm-scrollanim {
  opacity: 0;
}

.tm-scrollanim.scrollanim-action {
  animation: fadeIn 1s ease-in-out 0s 1 forwards;
}

/**
 * Button
 */
.tm-button, .tm-readmore,
a.tm-readmore,
button.tm-readmore,
a.tm-button,
a.tm-readmore,
button.tm-button,
button.tm-readmore {
  display: inline-block;
  height: 50px;
  min-width: 150px;
  background: #ff6c8d;
  color: #ffffff;
  text-align: center;
  line-height: 50px;
  font-weight: 600;
  border-radius: 4px;
  z-index: 1;
  position: relative;
  overflow: hidden;
  padding: 0 40px;
  vertical-align: middle;
}

.tm-button::before, .tm-readmore::before,
a.tm-button::before,
a.tm-readmore::before,
button.tm-button::before,
button.tm-readmore::before {
  content: "";
  position: absolute;
  left: 0;
  top: -100%;
  height: 100%;
  width: 100%;
  background: #3c424f;
  z-index: -1;
  transition: all 0.4s ease-in-out 0s;
}

.tm-button:hover::before, .tm-readmore:hover::before,
a.tm-button:hover::before,
a.tm-readmore:hover::before,
button.tm-button:hover::before,
button.tm-readmore:hover::before {
  top: 0;
}

.tm-button.tm-button-dark, .tm-button-dark.tm-readmore,
a.tm-button.tm-button-dark,
a.tm-button-dark.tm-readmore,
button.tm-button.tm-button-dark,
button.tm-button-dark.tm-readmore {
  background: #3c424f;
}

.tm-button.tm-button-dark::before, .tm-button-dark.tm-readmore::before,
a.tm-button.tm-button-dark::before,
a.tm-button-dark.tm-readmore::before,
button.tm-button.tm-button-dark::before,
button.tm-button-dark.tm-readmore::before {
  background: #ff6c8d;
}

.tm-button.tm-button-white, .tm-button-white.tm-readmore,
a.tm-button.tm-button-white,
a.tm-button-white.tm-readmore,
button.tm-button.tm-button-white,
button.tm-button-white.tm-readmore {
  background: #ffffff;
  color: #3c424f;
}

.tm-button.tm-button-white::before, .tm-button-white.tm-readmore::before,
a.tm-button.tm-button-white::before,
a.tm-button-white.tm-readmore::before,
button.tm-button.tm-button-white::before,
button.tm-button-white.tm-readmore::before {
  background: #ff6c8d;
}

.tm-button.tm-button-white:hover, .tm-button-white.tm-readmore:hover,
a.tm-button.tm-button-white:hover,
a.tm-button-white.tm-readmore:hover,
button.tm-button.tm-button-white:hover,
button.tm-button-white.tm-readmore:hover {
  color: #ffffff;
}

.tm-button.tm-button-transparent, .tm-button-transparent.tm-readmore,
a.tm-button.tm-button-transparent,
a.tm-button-transparent.tm-readmore,
button.tm-button.tm-button-transparent,
button.tm-button-transparent.tm-readmore {
  background: transparent;
  color: #ff6c8d;
  border: 1px solid #ff6c8d;
}

.tm-button.tm-button-transparent::before, .tm-button-transparent.tm-readmore::before,
a.tm-button.tm-button-transparent::before,
a.tm-button-transparent.tm-readmore::before,
button.tm-button.tm-button-transparent::before,
button.tm-button-transparent.tm-readmore::before {
  background: #ff6c8d;
}

.tm-button.tm-button-transparent:hover, .tm-button-transparent.tm-readmore:hover,
a.tm-button.tm-button-transparent:hover,
a.tm-button-transparent.tm-readmore:hover,
button.tm-button.tm-button-transparent:hover,
button.tm-button-transparent.tm-readmore:hover {
  color: #ffffff;
}

.tm-button.tm-button-transparent.tm-button-white, .tm-button-transparent.tm-button-white.tm-readmore,
a.tm-button.tm-button-transparent.tm-button-white,
a.tm-button-transparent.tm-button-white.tm-readmore,
button.tm-button.tm-button-transparent.tm-button-white,
button.tm-button-transparent.tm-button-white.tm-readmore {
  border-color: #ffffff;
  color: #ffffff;
}

.tm-button.tm-button-transparent.tm-button-white::before, .tm-button-transparent.tm-button-white.tm-readmore::before,
a.tm-button.tm-button-transparent.tm-button-white::before,
a.tm-button-transparent.tm-button-white.tm-readmore::before,
button.tm-button.tm-button-transparent.tm-button-white::before,
button.tm-button-transparent.tm-button-white.tm-readmore::before {
  background: #ffffff;
}

.tm-button.tm-button-transparent.tm-button-white:hover, .tm-button-transparent.tm-button-white.tm-readmore:hover,
a.tm-button.tm-button-transparent.tm-button-white:hover,
a.tm-button-transparent.tm-button-white.tm-readmore:hover,
button.tm-button.tm-button-transparent.tm-button-white:hover,
button.tm-button-transparent.tm-button-white.tm-readmore:hover {
  color: #3c424f;
}

.tm-button.tm-button-transparent.tm-button-dark, .tm-button-transparent.tm-button-dark.tm-readmore,
a.tm-button.tm-button-transparent.tm-button-dark,
a.tm-button-transparent.tm-button-dark.tm-readmore,
button.tm-button.tm-button-transparent.tm-button-dark,
button.tm-button-transparent.tm-button-dark.tm-readmore {
  border-color: #3c424f;
  color: #3c424f;
}

.tm-button.tm-button-transparent.tm-button-dark::before, .tm-button-transparent.tm-button-dark.tm-readmore::before,
a.tm-button.tm-button-transparent.tm-button-dark::before,
a.tm-button-transparent.tm-button-dark.tm-readmore::before,
button.tm-button.tm-button-transparent.tm-button-dark::before,
button.tm-button-transparent.tm-button-dark.tm-readmore::before {
  background: #3c424f;
}

.tm-button.tm-button-transparent.tm-button-dark:hover, .tm-button-transparent.tm-button-dark.tm-readmore:hover,
a.tm-button.tm-button-transparent.tm-button-dark:hover,
a.tm-button-transparent.tm-button-dark.tm-readmore:hover,
button.tm-button.tm-button-transparent.tm-button-dark:hover,
button.tm-button-transparent.tm-button-dark.tm-readmore:hover {
  color: #ffffff;
}

.tm-button.tm-button-small, .tm-button-small.tm-readmore,
a.tm-button.tm-button-small,
a.tm-button-small.tm-readmore,
button.tm-button.tm-button-small,
button.tm-button-small.tm-readmore {
  height: 45px;
  line-height: 45px;
  min-width: 100px;
  padding: 0 25px;
}

@media only screen and (max-width: 767px) {
  .tm-button, .tm-readmore,
  a.tm-readmore,
  button.tm-readmore,
  a.tm-button,
  a.tm-readmore,
  button.tm-button,
  button.tm-readmore {
    height: 45px;
    line-height: 45px;
    min-width: 100px;
    padding: 0 25px;
  }
}

/* Readmore */
.tm-readmore,
a.tm-readmore,
button.tm-readmore {
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  min-width: 100px;
  font-size: 14px;
  border: 1px solid #ff6c8d;
  background: transparent;
  color: #ff6c8d;
}

.tm-readmore::before,
a.tm-readmore::before,
button.tm-readmore::before {
  background: #ff6c8d;
}

.tm-readmore:hover,
a.tm-readmore:hover,
button.tm-readmore:hover {
  color: #ffffff;
}

.tm-readmore.tm-readmore-dark,
a.tm-readmore.tm-readmore-dark,
button.tm-readmore.tm-readmore-dark {
  border-color: #3c424f;
  color: #3c424f;
}

.tm-readmore.tm-readmore-dark::before,
a.tm-readmore.tm-readmore-dark::before,
button.tm-readmore.tm-readmore-dark::before {
  background: #ff6c8d;
}

.tm-readmore.tm-readmore-dark:hover,
a.tm-readmore.tm-readmore-dark:hover,
button.tm-readmore.tm-readmore-dark:hover {
  color: #ffffff;
  border-color: #ff6c8d;
}

.tm-readmore.tm-readmore-white,
a.tm-readmore.tm-readmore-white,
button.tm-readmore.tm-readmore-white {
  border-color: #ffffff;
  color: #ffffff;
}

.tm-readmore.tm-readmore-white::before,
a.tm-readmore.tm-readmore-white::before,
button.tm-readmore.tm-readmore-white::before {
  background: #ffffff;
}

.tm-readmore.tm-readmore-white:hover,
a.tm-readmore.tm-readmore-white:hover,
button.tm-readmore.tm-readmore-white:hover {
  color: #3c424f;
  border-color: #ffffff;
}

/* Video button */
.tm-videobutton,
a.tm-videobutton,
button.tm-videobutton {
  display: inline-block;
  height: 130px;
  width: 130px;
  padding: 0;
  border: none;
  outline: none;
  background: rgba(255, 108, 141, 0.3);
  border-radius: 1000px;
  font-size: 130px;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
}

.tm-videobutton i,
a.tm-videobutton i,
button.tm-videobutton i {
  vertical-align: middle;
  margin-top: -16px;
  display: inline-block;
}

.tm-videobutton:hover,
a.tm-videobutton:hover,
button.tm-videobutton:hover {
  background: #ff6c8d;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-videobutton,
  a.tm-videobutton,
  button.tm-videobutton {
    height: 100px;
    width: 100px;
    font-size: 100px;
  }
  .tm-videobutton i,
  a.tm-videobutton i,
  button.tm-videobutton i {
    margin-top: -12px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-videobutton,
  a.tm-videobutton,
  button.tm-videobutton {
    height: 100px;
    width: 100px;
    font-size: 100px;
  }
  .tm-videobutton i,
  a.tm-videobutton i,
  button.tm-videobutton i {
    margin-top: -12px;
  }
}

/**
 * Title
 */
.tm-sectiontitle {
  margin-bottom: 55px;
}

.tm-sectiontitle h2 {
  margin-top: -8px;
  margin-bottom: 12px;
}

.tm-sectiontitle-divider {
  display: inline-block;
  max-width: 16px;
  position: relative;
  line-height: 1;
  margin-bottom: 20px;
}

.tm-sectiontitle-divider::before, .tm-sectiontitle-divider::after {
  content: "";
  position: absolute;
  width: 70px;
  height: 2px;
  background: #3c424f;
  top: 50%;
}

.tm-sectiontitle-divider::before {
  left: auto;
  right: calc(100% + 10px);
}

.tm-sectiontitle-divider::after {
  left: calc(100% + 10px);
  right: auto;
}

.tm-sectiontitle-divider img {
  width: 100%;
}

.tm-sectiontitle p {
  margin-bottom: 0;
}

/**
 * Feature
 */
.tm-feature {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.17);
  padding: 22px 20px;
  background: #ffffff;
}

.tm-feature img {
  height: 100px;
  width: 100px;
}

.tm-feature h4 {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  margin-top: 22px;
}

/**
 * Form
 */
.tm-form-inner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -30px;
}

.tm-form-field {
  margin-top: 30px;
  flex: 0 0 100%;
  max-width: 100%;
}

.tm-form-field a {
  color: #686b77;
}

.tm-form-field a:hover {
  color: #ff6c8d;
}

.tm-form-fieldhalf {
  flex: 0 0 calc(50% - 15px);
  max-width: calc(50% - 15px);
}

.tm-form label {
  font-size: 14px;
  line-height: 22px;
}

.tm-form input,
.tm-form textarea,
.tm-form select {
  border: 1px solid #d2d2d2;
  background: transparent;
  color: #686b77;
  border-radius: 4px;
}

.tm-form input::-webkit-input-placeholder,
.tm-form textarea::-webkit-input-placeholder,
.tm-form select::-webkit-input-placeholder {
  color: #686b77;
}

.tm-form input:-moz-placeholder,
.tm-form textarea:-moz-placeholder,
.tm-form select:-moz-placeholder {
  color: #686b77;
}

.tm-form input::-moz-placeholder,
.tm-form textarea::-moz-placeholder,
.tm-form select::-moz-placeholder {
  color: #686b77;
}

.tm-form input:-ms-input-placeholder,
.tm-form textarea:-ms-input-placeholder,
.tm-form select:-ms-input-placeholder {
  color: #686b77;
}

.tm-form input:focus,
.tm-form textarea:focus,
.tm-form select:focus {
  border-color: rgba(255, 108, 141, 0.3);
}

.tm-form textarea {
  min-height: 162px;
}

.tm-form .nice-select {
  border: 1px solid #d2d2d2;
  background: transparent;
  color: #686b77;
  border-radius: 3px;
}

.tm-form .nice-select.open {
  border-color: rgba(255, 108, 141, 0.3);
}

@media only screen and (max-width: 575px) {
  .tm-form-fieldhalf {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/**
 * Selectbox
 */
.nice-select {
  height: 50px;
  border-radius: 3px;
  line-height: 48px;
  padding: 0 15px;
  padding-right: 35px;
  width: 100%;
  border-color: #ebebeb;
}

.nice-select::after {
  content: "\e64b";
  font-family: 'Themify';
  font-size: 10px;
  position: absolute;
  border: 0;
  transform: rotate(0deg);
  transform-origin: center center;
  height: 9px;
  width: 9px;
  line-height: 1;
  text-align: center;
}

.nice-select .current {
  display: block;
  padding: 0;
}

.nice-select .list {
  border-radius: 0;
  margin-bottom: 0;
  min-width: 180px;
  width: 100%;
  margin-top: 1px;
}

.nice-select .list .option {
  padding: 0 15px;
  min-height: auto;
  line-height: 30px;
}

.nice-select .list .option:hover {
  background: #ff6c8d;
  color: #ffffff;
}

.nice-select:focus {
  border-color: #ff6c8d;
}

.nice-select.open {
  border-color: #ff6c8d;
}

.nice-select.open:after {
  transform: rotate(180deg);
}

@media only screen and (max-width: 767px) {
  .nice-select {
    height: 44px;
    line-height: 42px;
  }
}

/**
 * Tabgroup
 */
.tm-tabgroup {
  margin-bottom: 45px;
  background: #fdf4f3;
  border-radius: 1000px;
  display: inline-flex;
  justify-content: center;
}

.tm-tabgroup li {
  margin-top: 1px;
  margin-right: 1px;
}

.tm-tabgroup li a {
  display: inline-block;
  height: 50px;
  line-height: 48px;
  min-width: 170px;
  text-align: center;
  padding: 0 35px;
  color: #333333;
  font-size: 18px;
  font-weight: 700;
  border-radius: 100px;
}

.tm-tabgroup li a:hover {
  color: #ff6c8d;
}

.tm-tabgroup li a.active {
  background: #ff6c8d;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-tabgroup li a {
    padding: 0 25px;
    font-size: 16px;
    background: #fdf4f3;
    min-width: inherit;
  }
}

@media only screen and (max-width: 767px) {
  .tm-tabgroup {
    background: transparent;
  }
  .tm-tabgroup li {
    width: 100%;
  }
  .tm-tabgroup li a {
    display: block;
    height: 45px;
    line-height: 43px;
    padding: 0 25px;
    font-size: 16px;
    background: #fdf4f3;
    min-width: inherit;
  }
}

.tm-tabgroup2 {
  padding-left: 0;
  margin-bottom: 40px;
  background: #fdf4f3;
  padding: 15px 0;
  text-align: center;
  display: block;
}

.tm-tabgroup2 li {
  list-style: none;
  display: inline-block;
}

.tm-tabgroup2 li:not(:last-child) a {
  border-right: 1px solid #ebebeb;
}

.tm-tabgroup2 li a {
  color: #686b77;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 3px 20px;
}

.tm-tabgroup2 li a.active {
  color: #ff6c8d;
}

@media only screen and (max-width: 575px) {
  .tm-tabgroup2 li a {
    padding: 3px 15px;
  }
}

.tab-content {
  width: 100%;
}

.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/**
 * Accordion
 */
.tm-accordion .card {
  border: none;
  background: transparent;
}

.tm-accordion .card:not(:last-child) {
  margin-bottom: 10px;
}

.tm-accordion .card-header {
  padding: 0;
  background: transparent;
  border: 0;
}

.tm-accordion .card-header button {
  height: auto;
  display: block;
  width: 100%;
  text-align: left;
  line-height: 28px;
  text-decoration: none !important;
  border: 0;
  position: relative;
  white-space: inherit;
  color: #3c424f;
  font-size: 18px;
  font-weight: 400;
  font-family: "PT Serif", serif;
  border-bottom: 1px solid #ebebeb;
  padding: 8px 0;
  padding-left: 45px;
}

.tm-accordion .card-header button::after {
  content: "\e65f";
  font-family: 'themify';
  position: absolute;
  left: 0;
  top: 7px;
  font-size: 18px;
}

.tm-accordion .card-header button.collapsed::after {
  content: "\e65f";
}

.tm-accordion .card-header button:not(.collapsed) {
  color: #ff6c8d;
}

.tm-accordion .card-header button:not(.collapsed):after {
  content: "\e65c";
}

.tm-accordion .card-body {
  padding: 30px 0 30px 45px;
  border-radius: 3px;
}

.tm-accordion .card-body p:last-of-type {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .tm-accordion .card-header button {
    font-size: 16px;
    padding-left: 30px;
  }
  .tm-accordion .card-body {
    padding-left: 30px;
  }
}

/**
 * Pagination
 */
.tm-pagination ul {
  padding-left: 0;
  margin-bottom: 0;
  font-size: 0;
}

.tm-pagination ul li {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
}

.tm-pagination ul li:not(:last-child) {
  margin-right: 10px;
}

.tm-pagination ul li a {
  display: inline-block;
  color: #3c424f;
  font-family: "PT Serif", serif;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  min-width: 50px;
  height: 50px;
  line-height: 50px;
  padding: 0 3px;
  border: 0;
  background: #fdf4f3;
}

.tm-pagination ul li a:hover {
  background: rgba(255, 108, 141, 0.5);
  color: #ffffff;
}

.tm-pagination ul li.is-active a {
  background: #ff6c8d;
  color: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-pagination ul li a {
    min-width: 45px;
    height: 45px;
    line-height: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-pagination ul li a {
    min-width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}

/**
 * Header
 */
.tm-header {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 99;
}

.tm-header-toparea {
  border-bottom: 1px solid #ebebeb;
  background: #ffffff;
}

.tm-header-info {
  padding-left: 0;
  margin-bottom: 0;
  font-size: 0;
}

.tm-header-info li {
  display: inline-block;
  margin-right: 30px;
  list-style: none;
  font-size: 14px;
  line-height: 28px;
  color: #3c424f;
  vertical-align: middle;
}

.tm-header-info li b {
  font-family: "PT Serif", serif;
}

.tm-header-info li i {
  color: #ff6c8d;
  margin-right: 5px;
}

.tm-header-info li a {
  color: #3c424f;
}

.tm-header-info li a:hover {
  color: #ff6c8d;
}

.tm-header-icons {
  padding-left: 0;
  margin-bottom: 0;
  text-align: right;
  padding: 10px 0;
}

.tm-header-icons li {
  list-style: none;
  display: inline-block;
  padding: 0 12px;
}

.tm-header-icons li:not(:last-child) {
  border-right: 1px solid #ebebeb;
}

.tm-header-icons li:first-child {
  display: none;
}

.tm-header-icons li:first-child a {
  font-size: 14px;
}

.tm-header-icons li a,
.tm-header-icons li button {
  display: inline-block;
  padding: 0;
  border: 0;
  font-size: 16px;
  color: #3c424f;
  height: auto;
  padding: 3px;
}

.tm-header-icons li a:hover,
.tm-header-icons li button:hover {
  color: #ff6c8d;
}

.tm-header-inner {
  padding: 11px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
}

.tm-header-inner .tm-button, .tm-header-inner .tm-readmore {
  padding: 0 15px;
}

.tm-header-logo {
  margin-right: 20px;
}

.tm-header-logo img {
  max-height: 100%;
}

.tm-header-nav {
  flex-grow: 1;
  text-align: right;
}

.tm-header-button {
  margin-left: 40px;
}

.tm-header-bottomarea {
  background: #ffffff;
}

.tm-header-bottominside {
  position: relative;
}

.tm-header-searcharea {
  padding: 15px 0;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #ffffff;
  z-index: 101;
  transition: all 0.4s ease-in-out;
  transform: scaleX(0);
}

.tm-header-searcharea form {
  position: relative;
  flex-grow: 100;
}

.tm-header-searcharea form input {
  border: 1px solid #d2d2d2;
  border-radius: 100px;
  padding-left: 30px;
  padding-right: 50px;
  background: rgba(253, 244, 243, 0.2);
}

.tm-header-searcharea form button,
.tm-header-searcharea form input[type="submit"] {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  border: 0;
}

.tm-header-searcharea form button:hover,
.tm-header-searcharea form input[type="submit"]:hover {
  color: #ff6c8d;
}

.tm-header-searcharea.is-visible {
  transform: scaleX(1);
}

.tm-header-searchclose {
  border: 0;
  color: #ff6c8d;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-header-button {
    margin-right: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-header-info {
    text-align: center;
    padding: 10px 0;
    border-bottom: 1px solid #ebebeb;
  }
  .tm-header-info li {
    margin: 0 15px;
  }
  .tm-header-icons {
    text-align: center;
  }
  .tm-header-icons li:first-child {
    display: inline;
  }
  .tm-header-icons li {
    padding: 0 9px;
  }
  .tm-header-logo {
    height: 53px;
    display: inline-block;
  }
  .tm-header-button {
    display: none;
  }
  .tm-header-searcharea form button,
  .tm-header-searcharea form input[type="submit"] {
    height: 45px;
    line-height: 45px;
  }
}

.tm-header-sticky {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.tm-header-sticky.is-sticky .tm-header-bottomarea {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  animation: slideInDown 0.5s ease-in-out 0s 1 both;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

/* Mobilemenu */
.tm-mobilenav {
  position: absolute;
  top: 100%;
  width: 100%;
}

.tm-mobilenav .mean-bar {
  background: #0c1923;
  padding: 0;
  min-height: 0;
  z-index: 99;
}

.tm-mobilenav a.meanmenu-reveal {
  margin-top: -53px;
  text-indent: 0;
  padding: 0;
  text-align: center;
  width: 25px;
  height: 25px;
  line-height: 20px;
  color: #3c424f;
  font-size: 24px !important;
}

.tm-mobilenav .mean-nav {
  margin-top: 0;
  background: #ff6c8d;
  position: absolute;
  left: 0;
  top: 100%;
  right: 0;
  width: 100%;
  box-shadow: 0 1px 5px rgba(209, 208, 208, 0.5);
}

.tm-mobilenav .mean-nav > ul {
  max-height: 201px;
  overflow-y: auto;
}

.tm-mobilenav .mean-nav > ul li a {
  padding: 7px 5%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 14px;
  font-family: "PT Serif", serif;
  text-transform: inherit;
}

.tm-mobilenav .mean-nav > ul li a:hover {
  background: #ffffff;
  color: #ff6c8d;
}

.tm-mobilenav .mean-nav > ul li a.mean-expand {
  padding: 0 6px !important;
  height: 39px;
  line-height: 39px;
  background: rgba(255, 108, 141, 0.7);
  color: #ffffff;
  margin-top: 0;
}

.tm-mobilenav .mean-nav > ul li a.mean-expand:hover {
  background: rgba(255, 255, 255, 0.1);
}

.tm-mobilenav .mean-nav > ul li li a {
  opacity: 1;
}

.tm-mobilenav .mean-nav > ul li ul li a {
  padding: 7px 10%;
}

.tm-mobilenav .mean-nav > ul li ul ul li a {
  padding: 7px 15%;
}

.tm-mobilenav .mean-nav > ul li ul ul ul li a {
  padding: 7px 20%;
}

@media only screen and (max-width: 767px) {
  .tm-mobilenav a.meanmenu-reveal {
    margin-top: -50px;
  }
}

/**
 * Navigation
 */
.tm-header-nav ul {
  margin-bottom: 0;
  padding-left: 0;
}

.tm-header-nav ul li {
  list-style: none;
  display: inline-block;
  margin: 0 20px;
  position: relative;
}

.tm-header-nav ul li:first-child {
  margin-left: 0;
}

.tm-header-nav ul li:last-child {
  margin-right: 0;
}

.tm-header-nav ul li a {
  display: inline-block;
  padding: 15px 0;
  color: #3c424f;
  font-family: "PT Serif", serif;
}

.tm-header-nav ul li.current > a, .tm-header-nav ul li:hover > a {
  color: #ff6c8d;
}

.tm-header-nav .tm-header-nav-dropdown > a::after {
  content: "\e64b";
  font-family: 'Themify';
  font-size: 12px;
  padding-left: 3px;
  vertical-align: middle;
}

.tm-header-nav .tm-header-nav-dropdown ul {
  position: absolute;
  text-align: left;
  padding: 10px 0;
  left: 100%;
  top: 0;
  width: 250px;
  background: #ffffff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
  border-top: 4px solid #ff6c8d;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  border-radius: 0 0 3px 3px;
  transition: all 0.1s ease-in-out 0s;
}

.tm-header-nav .tm-header-nav-dropdown ul li {
  display: block;
  padding: 0 15px;
  transition: all 0.1s ease-in-out 0s;
  margin: 0;
}

.tm-header-nav .tm-header-nav-dropdown ul li a {
  padding: 6px 0;
  line-height: 1.3;
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #686b77;
}

.tm-header-nav .tm-header-nav-dropdown ul li.has-child::after {
  content: "";
  clear: both;
  display: table;
}

.tm-header-nav .tm-header-nav-dropdown ul li.has-child > a::after {
  content: "\e649";
  font-family: 'Themify';
  font-size: 12px;
  float: right;
}

.tm-header-nav .tm-header-nav-dropdown ul li:hover > a {
  color: #ff6c8d;
}

.tm-header-nav .tm-header-nav-dropdown ul li:hover > ul {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.tm-header-nav .tm-header-nav-dropdown > ul {
  left: 0;
  top: 100%;
}

.tm-header-nav .tm-header-nav-dropdown:hover > ul {
  visibility: visible;
  opacity: 1;
  z-index: 9;
}

.tm-header-nav .tm-header-nav-dropdown.overflow-element ul {
  left: auto;
  right: 100%;
}

.tm-header-nav .tm-header-nav-dropdown.overflow-element > ul {
  left: auto;
  right: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tm-header-nav ul li {
    margin: 0 15px;
  }
}

/**
 * Heroslider
 */
.tm-heroslider {
  position: relative;
  background-position: right center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex !important;
  align-items: center;
}

.tm-heroslider::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: none;
}

.tm-heroslider-area {
  position: relative;
}

.tm-heroslider-content {
  padding: 100px 0;
}

.tm-heroslider-content h1 {
  margin-top: -10px;
  font-size: 48px;
  line-height: 60px;
}

.tm-heroslider-content p {
  font-size: 20px;
  line-height: 30px;
  margin-top: 30px;
}

.tm-heroslider-content .tm-button, .tm-heroslider-content .tm-readmore {
  margin-top: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tm-heroslider-content h1 {
    margin-top: -8px;
    font-size: 42px;
    line-height: 52px;
  }
  .tm-heroslider-content p {
    font-size: 18px;
    line-height: 26px;
    margin-top: 20px;
  }
  .tm-heroslider-content .tm-button, .tm-heroslider-content .tm-readmore {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-heroslider::before {
    display: block;
  }
  .tm-heroslider-content h1 {
    margin-top: -5px;
    font-size: 36px;
    line-height: 44px;
  }
  .tm-heroslider-content p {
    font-size: 18px;
    line-height: 26px;
    margin-top: 20px;
  }
  .tm-heroslider-content .tm-button, .tm-heroslider-content .tm-readmore {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-heroslider::before {
    display: block;
  }
  .tm-heroslider-content h1 {
    margin-top: -5px;
    font-size: 36px;
    line-height: 44px;
  }
  .tm-heroslider-content p {
    font-size: 18px;
    line-height: 26px;
    margin-top: 15px;
  }
  .tm-heroslider-content .tm-button, .tm-heroslider-content .tm-readmore {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .tm-heroslider {
    background-position: 85% center;
  }
  .tm-heroslider-content h1 {
    margin-top: -5px;
    font-size: 32px;
    line-height: 40px;
  }
  .tm-heroslider-content p {
    font-size: 16px;
    line-height: 24px;
  }
}

.tm-heroslider-slider.slick-dotted {
  padding-bottom: 0;
}

.tm-heroslider-slider.slick-dotted .slick-dots {
  position: absolute;
  left: 0;
  top: auto;
  bottom: 30px;
  z-index: 5;
}

.tm-heroslider-slider.slick-dotted .slick-dots li {
  background: #686b77;
  width: 30px;
  height: 3px;
  border-radius: 0;
}

.tm-heroslider-slider.slick-dotted .slick-dots li:hover {
  opacity: 0.5;
  background: #ff6c8d;
}

.tm-heroslider-slider.slick-dotted .slick-dots li.slick-active {
  background: #ff6c8d;
}

.tm-heroslider-slider .tm-heroslider-contentwrapper {
  overflow: hidden;
}

.tm-heroslider-slider .tm-heroslider-content {
  transition: all 1s ease-in-out 0.5s;
  transform: translateX(-100%);
}

.tm-heroslider-slider .tm-heroslider.slick-current .tm-heroslider-content {
  transform: translateX(0);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tm-heroslider-slider.slick-dotted .slick-dots {
    bottom: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-heroslider-slider.slick-dotted .slick-dots {
    bottom: 20px;
  }
}

.tm-heroslider-social {
  padding-left: 0;
  margin-bottom: 0;
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 35px;
  z-index: 5;
  text-align: center;
  padding: 15px 0;
}

.tm-heroslider-social::before {
  content: "";
  position: absolute;
  left: 50%;
  height: 50px;
  width: 1px;
  background: #686b77;
  top: auto;
  bottom: 100%;
}

.tm-heroslider-social::after {
  content: "";
  position: absolute;
  left: 50%;
  height: 50px;
  width: 1px;
  background: #686b77;
  top: 100%;
  bottom: auto;
}

.tm-heroslider-social li {
  list-style: none;
  display: inline-block;
  margin: 7px 0;
  position: relative;
}

.tm-heroslider-social li a {
  color: #3c424f;
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 100px;
  border: 1px solid #686b77;
  font-size: 16px;
  text-align: center;
}

.tm-heroslider-social li a i {
  line-height: 35px;
}

.tm-heroslider-social li a:hover {
  background: #ff6c8d;
  border-color: #ff6c8d;
  color: #ffffff;
}

.tm-heroslider-social li span {
  position: absolute;
  left: 100%;
  top: 0;
  line-height: 35px;
  visibility: hidden;
  opacity: 0;
  padding-left: 10px;
  font-size: 14px;
}

.tm-heroslider-social li:hover span {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 1350px) {
  .tm-heroslider-social {
    display: none;
  }
}

/**
 * About
 */
.tm-about {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  padding: 100px 70px;
  background-color: #ffffff;
  background-size: contain;
  background-position: center right;
  position: relative;
}

.tm-about-area {
  background-size: contain;
  background-position: left top;
}

.tm-about:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.87);
  display: none;
}

.tm-about h3 {
  margin-top: -10px;
}

.tm-about .tm-button, .tm-about .tm-readmore {
  margin-top: 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tm-about {
    padding: 60px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-about {
    padding: 60px 40px;
  }
  .tm-about::before {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .tm-about {
    padding: 60px 40px;
  }
  .tm-about::before {
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .tm-about {
    padding: 45px 30px;
    background-size: cover;
  }
}

/**
 * Video Area
 */
.tm-video {
  padding: 240px 0;
}

.tm-video-area::before {
  background-color: #f1b79e;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tm-video {
    padding: 180px 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-video {
    padding: 150px 0;
  }
}

@media only screen and (max-width: 767px) {
  .tm-video {
    padding: 150px 0;
  }
}

/**
 * Service
 */
.tm-service {
  padding: 40px 15px 50px 15px;
}

.tm-service-wrapper {
  border-left: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
}

.tm-service-wrapper [class^="col"] {
  border-right: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

.tm-service .tm-readmore {
  margin-top: 10px;
}

.tm-service-icon {
  font-size: 100px;
  color: #3c424f;
  margin-bottom: 20px;
  display: inline-block;
  transition: all 0.4s ease-in-out;
}

.tm-service-icon i {
  display: inline-block;
  vertical-align: middle;
}

.tm-service:hover .tm-service-icon {
  color: #ff6c8d;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-service-icon {
    font-size: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-service {
    padding: 30px 15px 40px 15px;
  }
  .tm-service-icon {
    font-size: 80px;
  }
}

/**
 * Pricing & Compare
 */
.tm-pricing {
  margin-right: 20px;
  overflow: hidden;
}

.tm-pricing h3 {
  margin-top: -8px;
}

.tm-pricing ul {
  padding-left: 0;
  margin-bottom: 0;
}

.tm-pricing ul li {
  list-style: none;
  position: relative;
}

.tm-pricing ul li::after {
  content: "";
  clear: both;
  display: table;
}

.tm-pricing ul li:not(:last-child) {
  margin-bottom: 15px;
}

.tm-pricing ul li .name {
  position: relative;
}

.tm-pricing ul li .name::after {
  content: "";
  position: absolute;
  left: calc(100% + 18px);
  height: 1px;
  width: 600px;
  background: #ebebeb;
  top: 12px;
}

.tm-pricing ul li .price {
  float: right;
  padding-left: 20px;
  background: #fdf4f3;
  position: relative;
  z-index: 1;
}

.tm-pricing .tm-button, .tm-pricing .tm-readmore {
  margin-top: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tm-pricing {
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-pricing {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .tm-pricing {
    margin-right: 0;
  }
}

.tm-beforeafter h3 {
  margin-top: -8px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-beforeafter {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-beforeafter {
    margin-top: 40px;
  }
}

/**
 * Appointment
 */
.tm-appointment-box h3 {
  margin-top: -8px;
}

.tm-appointment-image {
  margin-right: 85px;
  margin-left: -150px;
  margin-bottom: -100px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .tm-appointment-image {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1599px) {
  .tm-appointment-image {
    margin-left: -95px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tm-appointment-image {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-appointment-image {
    text-align: center;
    max-width: 60%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: -80px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-appointment-image {
    text-align: center;
    max-width: 90%;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: -70px;
  }
}

@media only screen and (max-width: 575px) {
  .tm-appointment-image {
    max-width: 100%;
  }
}

/**
 * Product
 */
.tm-product-topside {
  position: relative;
  overflow: hidden;
}

.tm-product-topside img {
  width: 100%;
}

.tm-product-actions {
  padding-left: 0;
  margin-bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  bottom: 0;
  width: 100%;
  background: transparent;
  padding: 10px 20px;
  text-align: center;
  font-size: 0;
  z-index: 1;
}

.tm-product-actions::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(255, 108, 141, 0.8);
  transition: all 0.4s ease-in-out;
  transform: translateY(100%);
  z-index: -1;
}

.tm-product-actions li {
  list-style: none;
  display: inline-block;
  font-size: 18px;
  vertical-align: middle;
  visibility: hidden;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.4s ease-in-out;
}

.tm-product-actions li:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}

.tm-product-actions li a,
.tm-product-actions li button {
  display: inline-block;
  vertical-align: middle;
  padding: 8px 20px;
  color: #ffffff;
  outline: none;
  border: none;
  height: auto;
}

.tm-product-bottomside {
  padding-top: 25px;
}

.tm-product-bottomside h6 {
  margin-bottom: 8px;
}

.tm-product-bottomside h6 a {
  color: #3c424f;
}

.tm-product-bottomside h6 a:hover {
  color: #ff6c8d;
}

.tm-product-bottomside .tm-ratingbox {
  margin-top: 5px;
}

.tm-product-price del {
  margin-right: 10px;
}

.tm-product:hover .tm-product-actions::before {
  transform: translateY(0);
}

.tm-product:hover .tm-product-actions li {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.tm-ratingbox {
  font-size: 0;
}

.tm-ratingbox span {
  font-size: 18px;
  display: inline-block;
  vertical-align: middle;
}

.tm-ratingbox span:not(:last-child) {
  margin-right: 4px;
}

.tm-ratingbox span.is-active {
  color: #ff6c8d;
}

/**
 * Offer
 */
.tm-offer-left {
  padding-right: 40px;
}

.tm-offer-left h1 {
  margin-top: -8px;
}

.tm-offer-left h3 span {
  font-size: 50px;
  color: #ff6c8d;
}

.tm-offer-left p {
  font-size: 18px;
  line-height: 28px;
}

.tm-offer-left .tm-button, .tm-offer-left .tm-readmore {
  margin-top: 20px;
}

.tm-offer-right {
  text-align: center;
}

.tm-offer-right .tm-countdown {
  margin-top: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tm-offer-left {
    padding-right: 0;
  }
  .tm-offer-left h3 span {
    font-size: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-offer-left {
    padding-right: 0;
  }
  .tm-offer-left h3 span {
    font-size: 40px;
  }
  .tm-offer-left .tm-button, .tm-offer-left .tm-readmore {
    margin-top: 20px;
  }
  .tm-offer-right {
    margin-bottom: 40px;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .tm-offer-left {
    padding-right: 0;
  }
  .tm-offer-left h3 span {
    font-size: 34px;
  }
  .tm-offer-left p {
    font-size: 16px;
  }
  .tm-offer-left .tm-button, .tm-offer-left .tm-readmore {
    margin-top: 20px;
  }
  .tm-offer-right {
    margin-bottom: 40px;
    text-align: left;
  }
}

/* Countdown */
.tm-countdown {
  margin-top: -30px;
}

.tm-countdown-pack {
  min-width: 100px;
  min-height: 100px;
  text-align: center;
  background: #ffffff;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  box-shadow: 0 1px 5px rgba(209, 208, 208, 0.5);
  position: relative;
}

.tm-countdown-pack::after {
  content: ":";
  position: absolute;
  left: calc(100% + 8px);
  top: 50%;
  font-family: "PT Serif", serif;
  color: #3c424f;
  font-size: 40px;
  transform: translateY(-50%);
}

.tm-countdown-pack:last-child::after {
  display: none;
}

.tm-countdown-pack:not(:last-child) {
  margin-right: 30px;
}

.tm-countdown-pack h2 {
  margin-bottom: 0;
  margin-top: -5px;
  line-height: 1;
}

.tm-countdown-pack h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin-bottom: 0;
  color: #686b77;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tm-countdown-pack {
    min-width: 90px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-countdown-pack {
    min-width: 90px;
  }
}

@media only screen and (max-width: 575px) {
  .tm-countdown-pack::after {
    display: none;
  }
}

/**
 * Testimonial
 */
.tm-testimonial-area {
  background-size: inherit;
  background-position: center right;
}

.tm-testimonial-wrapper {
  text-align: center;
  box-shadow: 0 0 10px rgba(204, 204, 204, 0.5);
  padding: 50px 110px;
  background: rgba(255, 255, 255, 0.7);
}

.tm-testimonial-authorimage {
  display: inline-block;
  height: 100px;
  width: 100px;
  border-radius: 100px;
  overflow: hidden;
  margin-bottom: 15px;
}

.tm-testimonial-authorimage img {
  width: 100%;
}

.tm-testimonial p {
  font-size: 18px;
  line-height: 28px;
}

.tm-testimonial p::before {
  content: '"';
  color: #ff6c8d;
  padding-right: 10px;
  font-style: italic;
}

.tm-testimonial p::after {
  content: '"';
  color: #ff6c8d;
  padding-left: 10px;
  font-style: italic;
}

.tm-testimonial h6 {
  margin-bottom: 5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-testimonial-area {
    background-size: cover;
  }
  .tm-testimonial-wrapper {
    padding: 30px 50px;
  }
  .tm-testimonial p {
    font-size: 16px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-testimonial-area {
    background-size: cover;
  }
  .tm-testimonial-wrapper {
    padding: 30px;
  }
  .tm-testimonial p {
    font-size: 14px;
    line-height: 23px;
  }
}

@media only screen and (max-width: 575px) {
  .tm-testimonial-wrapper {
    padding: 30px 15px;
  }
}

/**
 * Subscribe
 */
.tm-subscribe {
  text-align: center;
}

.tm-subscribe h3 {
  margin-top: -7px;
}

.tm-subscribe-form {
  position: relative;
  margin: 0 100px;
  margin-top: 35px;
}

.tm-subscribe-form input:not([type="submit"]) {
  background: #ffffff;
  border: 0px;
  height: 60px;
  line-height: 60px;
  border-radius: 4px;
  padding: 0 30px;
  padding-right: 85px;
}

.tm-subscribe-form button,
.tm-subscribe-form input[type="submit"] {
  position: absolute;
  right: 5px;
  top: 5px;
  min-width: auto;
  font-size: 32px;
  padding: 0 20px;
}

.tm-subscribe-form button i::before,
.tm-subscribe-form input[type="submit"] i::before {
  margin-top: -7px;
  display: inline-block;
}

.tm-subscribe-social {
  padding-left: 0;
  margin-bottom: 0;
  font-size: 0;
  margin-top: 30px;
}

.tm-subscribe-social li {
  list-style: none;
  display: inline-block;
  margin: 0 15px;
  font-size: 24px;
  vertical-align: middle;
}

.tm-subscribe-social li a {
  display: inline-block;
  font-size: 24px;
  color: #3c424f;
}

.tm-subscribe-social li a:hover {
  color: #ff6c8d;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-subscribe-form {
    margin: 0 50px;
    margin-top: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-subscribe-form {
    margin: 0 50px;
    margin-top: 35px;
  }
  .tm-subscribe-social li {
    margin: 0 10px;
  }
  .tm-subscribe-social li a {
    font-size: 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-subscribe-form input:not([type="submit"]) {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-subscribe-form {
    margin-left: 0;
    margin-right: 0;
  }
  .tm-subscribe-form input:not([type="submit"]) {
    padding-left: 15px;
    height: 55px;
    line-height: 55px;
  }
}

/**
 * Team Member
 */
.tm-member-topside {
  position: relative;
  overflow: hidden;
}

.tm-member-topside ul {
  padding-left: 0;
  margin-bottom: 0;
  position: absolute;
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
  background: rgba(255, 108, 141, 0.8);
  padding: 10px 20px;
  text-align: center;
  font-size: 0;
  transition: all 0.4s ease-in-out;
  transform: translateY(100%);
}

.tm-member-topside ul li {
  list-style: none;
  display: inline-block;
  font-size: 18px;
  vertical-align: middle;
}

.tm-member-topside ul li:not(:last-child) {
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}

.tm-member-topside ul li a,
.tm-member-topside ul li button {
  display: inline-block;
  vertical-align: middle;
  padding: 8px 20px;
  color: #ffffff;
  outline: none;
  border: none;
  height: auto;
}

.tm-member-bottomside {
  margin-top: 25px;
}

.tm-member-bottomside h6 {
  margin-bottom: 8px;
}

.tm-member-bottomside p {
  margin-bottom: 0;
}

.tm-member:hover .tm-member-topside ul {
  transform: translateY(0);
}

/**
 * Portfolio
 */
.tm-portfolio {
  position: relative;
}

.tm-portfolio-wrapper {
  padding: 0 15px;
}

.tm-portfolio::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}

.tm-portfolio-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  background: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  height: 50px;
  width: 50px;
  text-align: center;
  color: #ffffff;
  border-radius: 100px;
  border: 1px solid #ebebeb;
  margin-left: -25px;
  margin-top: -25px;
  pointer-events: none;
  transform: translateY(50px);
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease-out 0s;
  z-index: 1;
}

.tm-portfolio-icon i {
  line-height: 50px;
}

.tm-portfolio:hover::before {
  visibility: visible;
  opacity: 1;
}

.tm-portfolio:hover .tm-portfolio-icon {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 480px) {
  .tm-portfolio-wrapper {
    padding: 0;
  }
}

/**
 * Call to action
 */
.tm-calltoaction-image {
  text-align: center;
}

.tm-calltoaction-content {
  text-align: center;
  padding: 100px 0;
}

.tm-calltoaction-content h3 {
  margin-top: -8px;
}

.tm-calltoaction-content h4 span {
  color: #686b77;
}

.tm-calltoaction-content h4 a {
  color: #ff6c8d;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-calltoaction-image {
    margin-top: 80px;
  }
  .tm-calltoaction-content {
    padding-top: 40px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-calltoaction-image {
    margin-top: 70px;
  }
  .tm-calltoaction-content {
    padding-top: 40px;
    padding-bottom: 70px;
  }
}

/**
 * Blog
 */
.tm-blog-thumb {
  overflow: hidden;
}

.tm-blog-content {
  margin-top: 25px;
}

.tm-blog-content blockquote {
  margin-top: 25px;
  margin-bottom: 25px;
}

.tm-blog-content .tm-readmore {
  margin-top: 10px;
}

.tm-blog-meta {
  padding-left: 0;
  margin-bottom: 0;
  margin-bottom: 12px;
}

.tm-blog-meta li {
  list-style: none;
  display: inline-block;
  margin-bottom: 5px;
}

.tm-blog-meta li:not(:last-child) {
  margin-right: 20px;
}

.tm-blog-meta li i {
  color: #ff6c8d;
  margin-right: 5px;
}

.tm-blog-meta li a {
  color: #686b77;
}

.tm-blog-meta li a:hover {
  color: #ff6c8d;
}

.tm-blog-title a {
  color: #3c424f;
}

.tm-blog-title a:hover {
  color: #ff6c8d;
}

.tm-blog-2 {
  padding: 30px 0;
  border-bottom: 1px solid #ebebeb;
  display: flex;
}

.tm-blog-2:first-child {
  padding-top: 0;
}

.tm-blog-2:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.tm-blog-2 .tm-blog-thumb {
  margin-right: 30px;
  flex: 0 0 170px;
  max-width: 170px;
}

.tm-blog-2 .tm-blog-meta {
  margin-bottom: 0;
}

.tm-blog-2 .tm-blog-meta-comment {
  display: none;
}

.tm-blog-2 .tm-blog-content {
  margin-top: 0;
  flex: 0 0 calc(100% - 200px);
  max-width: calc(100% - 200px);
  overflow: hidden;
}

.tm-blog-2 .tm-blog-content p {
  display: none;
}

.tm-blog-2 .tm-blog-content .tm-readmore {
  margin-top: 5px;
}

.tm-blog-2 .tm-blog-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tm-blog-2 .tm-blog-thumb {
    flex: 0 0 140px;
    max-width: 140px;
  }
  .tm-blog-2 .tm-blog-content {
    flex: 0 0 calc(100% - 170px);
    max-width: calc(100% - 170px);
  }
}

@media only screen and (max-width: 767px) {
  .tm-blog-2 {
    flex-wrap: wrap;
  }
  .tm-blog-2 .tm-blog-thumb {
    flex: 0 0 140px;
    max-width: 140px;
  }
  .tm-blog-2 .tm-blog-content {
    padding-top: 20px;
    flex: 0 0 calc(100% - 170px);
    max-width: calc(100% - 170px);
  }
}

@media only screen and (max-width: 575px) {
  .tm-blog-2 .tm-blog-thumb {
    flex: 0 0 140px;
    max-width: 140px;
  }
  .tm-blog-2 .tm-blog-content {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.tm-blog-container .tm-blog-title,
.blogitem .tm-blog-title {
  font-size: 24px;
}

@media only screen and (max-width: 767px) {
  .tm-blog-container .tm-blog-title,
  .blogitem .tm-blog-title {
    font-size: 22px;
  }
}

/* Blog Details */
.blogitem .tm-blog-image img {
  transform: scale(1);
}

.blogitem .tm-blog-topside::before {
  display: none;
}

.blogitem-tags, .blogitem-share {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
  padding-top: 20px;
  padding-bottom: 20px;
}

.blogitem-tags-title, .blogitem-share-title {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 15px;
  line-height: 40px;
}

.blogitem-tags ul, .blogitem-share ul {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: -10px;
  margin-left: -10px;
  font-size: 0;
}

.blogitem-tags ul li, .blogitem-share ul li {
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 14px;
}

.blogitem-tags ul li a, .blogitem-share ul li a {
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #ebebeb;
  padding: 6px 22px;
  color: #686b77;
}

.blogitem-tags ul li a:hover, .blogitem-share ul li a:hover {
  background: #ff6c8d;
  border-color: #ff6c8d;
  color: #ffffff;
}

.blogitem-tags {
  margin-bottom: 0;
}

.blogitem-share {
  border-top: 0;
  margin-top: 0;
}

.blogitem-share ul li a i {
  font-size: 18px;
  margin-right: 5px;
}

.blogitem-share ul li.facebook a {
  color: #3b5999;
  border-color: #3b5999;
}

.blogitem-share ul li.facebook a:hover {
  background: #3b5999;
}

.blogitem-share ul li.twitter a {
  color: #55acee;
  border-color: #55acee;
}

.blogitem-share ul li.twitter a:hover {
  background: #55acee;
}

.blogitem-share ul li.linkedin a {
  color: #0077B5;
  border-color: #0077B5;
}

.blogitem-share ul li.linkedin a:hover {
  background: #0077B5;
}

.blogitem-share ul li.pinterest a {
  color: #bd081c;
  border-color: #bd081c;
}

.blogitem-share ul li.pinterest a:hover {
  background: #bd081c;
}

.blogitem-share ul li a:hover {
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .blogitem-tags ul li a {
    padding: 5px 15px;
  }
}

/**
 * Contact
 */
.tm-contact-area {
  background-size: inherit;
  background-position: left top;
}

.tm-contact-top {
  padding: 50px 0;
  box-shadow: 0 1px 5px rgba(209, 208, 208, 0.5);
  background: #ffffff;
}

.tm-contact-top .row [class*="col-"]:not(:last-child) {
  border-right: 1px solid #ebebeb;
}

.tm-contact-address {
  padding: 0 70px;
}

.tm-contact-address h4 {
  color: #ff6c8d;
  margin-bottom: 20px;
}

.tm-contact-addressblock {
  position: relative;
  padding-left: 100px;
}

.tm-contact-addressblock:not(:last-child) {
  margin-bottom: 20px;
}

.tm-contact-addressblock b {
  position: absolute;
  left: 0;
  top: 0;
}

.tm-contact-addressblock p {
  margin-bottom: 0;
}

.tm-contact-addressblock a {
  color: #686b77;
}

.tm-contact-addressblock a:hover {
  color: #ff6c8d;
}

.tm-contact-formwrapper h3 {
  margin-top: -7px;
  margin-bottom: 30px;
}

.tm-contact-map h3 {
  margin-top: -7px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tm-contact-top {
    padding: 30px 0;
  }
  .tm-contact-address {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-contact-top {
    padding: 0 50px;
  }
  .tm-contact-top .row [class*="col-"]:not(:last-child) {
    border-right: 0;
    border-bottom: 1px solid #ebebeb;
  }
  .tm-contact-address {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 767px) {
  .tm-contact-top {
    padding: 0 30px;
  }
  .tm-contact-top .row [class*="col-"]:not(:last-child) {
    border-right: 0;
    border-bottom: 1px solid #ebebeb;
  }
  .tm-contact-address {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 575px) {
  .tm-contact-top {
    padding: 0 15px;
  }
  .tm-contact-addressblock {
    padding-left: 75px;
  }
}

.google-map {
  min-height: 400px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .google-map {
    min-height: 350px;
  }
}

@media only screen and (max-width: 767px) {
  .google-map {
    min-height: 350px;
  }
}

@media only screen and (max-width: 575px) {
  .google-map {
    min-height: 300px;
  }
}

/**
 * Brand Logo
 */
.tm-brandlogo a {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  background: #ffffff;
  filter: grayscale(1);
  transition: all 0.4s ease-in-out;
}

.tm-brandlogo a:hover {
  filter: grayscale(0);
  box-shadow: 0 1px 5px rgba(209, 208, 208, 0.5);
}

.tm-brandlogo-slider .slick-list {
  margin: -15px;
}

.tm-brandlogo-slider .slick-list .tm-brandlogo {
  margin: 15px;
}

/**
 * Instagram
 */
.tm-instaphotos {
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tm-instaphotos li {
  max-width: calc(100% / 8);
  list-style: none;
}

.tm-instaphotos li a {
  display: inline-block;
  position: relative;
}

.tm-instaphotos li a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.4s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.tm-instaphotos li a ul {
  padding-left: 0;
  margin-bottom: 0;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  backface-visibility: hidden;
}

.tm-instaphotos li a ul li {
  display: inline-block;
  margin: 0 7px;
  color: #ffffff;
  font-size: 12px;
  transition: all 0.4s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.tm-instaphotos li a ul li i {
  font-size: 18px;
}

.tm-instaphotos li a:hover::before {
  visibility: visible;
  opacity: 1;
}

.tm-instaphotos li a:hover ul li {
  visibility: visible;
  opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-instaphotos li {
    max-width: calc(100% / 4);
  }
}

@media only screen and (max-width: 767px) {
  .tm-instaphotos li {
    max-width: calc(100% / 4);
  }
}

@media only screen and (max-width: 575px) {
  .tm-instaphotos li {
    max-width: calc(100% / 3);
  }
}

/**
 * Shop
 */
.tm-shop-header {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: -15px;
}

.tm-shop-header .nice-select {
  width: auto;
  min-width: 200px;
  margin-top: 15px;
}

.tm-shop-header .nice-select .list {
  width: 100%;
}

.tm-shop-countview {
  margin-bottom: 0;
  flex-grow: 100;
  margin-top: 15px;
}

/**
 * Breadcrumb
 */
.tm-breadcrumb-area {
  background-position: bottom center;
}

.tm-breadcrumb h2 {
  margin-top: -7px;
}

.tm-breadcrumb ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: 0;
  margin-bottom: -10px;
}

.tm-breadcrumb ul li {
  font-family: "PT Serif", serif;
  font-size: 18px;
  display: inline-block;
  color: #686b77;
}

.tm-breadcrumb ul li::after {
  content: '/';
  padding: 0 5px;
}

.tm-breadcrumb ul li:last-child::after {
  display: none;
}

.tm-breadcrumb ul li a {
  color: #686b77;
}

.tm-breadcrumb ul li a:hover {
  color: #ff6c8d;
}

@media only screen and (max-width: 767px) {
  .tm-breadcrumb ul li {
    font-size: 16px;
  }
}

/**
 * Widgets
 */
.single-widget {
  padding: 30px;
}

.single-widget .widget-title {
  font-weight: 700;
  position: relative;
  padding-bottom: 13px;
  margin-top: -5px;
  margin-bottom: 30px;
}

.single-widget .widget-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  height: 1px;
  width: 65px;
  background: #ff6c8d;
}

.single-widget ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

/* Widget Form */
.widget-search-form {
  position: relative;
}

.widget-search-form input[type="text"],
.widget-search-form input[type="search"] {
  border: 0;
  background: #ffffff;
  font-size: 14px;
  border-radius: 4px;
  padding: 0 15px;
  padding-right: 40px;
}

.widget-search-form input[type="text"]:focus,
.widget-search-form input[type="search"]:focus {
  border-color: #ebebeb;
}

.widget-search-form button,
.widget-search-form input[type="submit"] {
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  background: transparent;
  padding: 0 15px;
  border: 0;
  color: #ff6c8d;
}

.widget-search-form button i,
.widget-search-form input[type="submit"] i {
  line-height: 48px;
  vertical-align: text-top;
}

.widget-search-form button:hover,
.widget-search-form input[type="submit"]:hover {
  background: transparent;
  border-color: transparent;
  color: #ff6c8d;
}

@media only screen and (max-width: 767px) {
  .widget-search-form button i,
  .widget-search-form input[type="submit"] i {
    line-height: 43px;
  }
}

/* Widget Categories */
.widget-categories ul li,
.widget-sizes ul li {
  padding-top: 8px;
}

.widget-categories ul li:first-child,
.widget-sizes ul li:first-child {
  padding-top: 0;
}

.widget-categories ul li a,
.widget-sizes ul li a {
  display: block;
  font-weight: 400;
  color: #686b77;
}

.widget-categories ul li a::after,
.widget-sizes ul li a::after {
  content: "";
  clear: both;
  display: table;
}

.widget-categories ul li a:before,
.widget-sizes ul li a:before {
  content: "\e661";
  font-family: 'Themify';
  padding-right: 15px;
  font-size: 10px;
}

.widget-categories ul li a span,
.widget-sizes ul li a span {
  float: right;
}

.widget-categories ul li a span::before,
.widget-sizes ul li a span::before {
  content: "(";
}

.widget-categories ul li a span::after,
.widget-sizes ul li a span::after {
  content: ")";
}

.widget-categories ul li a:hover,
.widget-sizes ul li a:hover {
  color: #ff6c8d;
}

/* Widget Recentpost */
.widget-recentpost ul li {
  display: flex;
  align-items: center;
  padding: 25px 0;
  border-bottom: 1px solid #eeeeee;
}

.widget-recentpost ul li:first-child {
  padding-top: 0;
}

.widget-recentpost-image {
  flex: 0 0 70px;
  max-width: 70px;
  margin-right: 20px;
  position: relative;
  align-self: flex-start;
}

.widget-recentpost-image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}

.widget-recentpost-image::after {
  content: "\e62d";
  font-family: "Themify";
  position: absolute;
  left: 50%;
  top: 50%;
  color: #ffffff;
  font-size: 18px;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  margin-top: 15px;
}

.widget-recentpost-image:hover::before {
  opacity: 1;
  visibility: visible;
}

.widget-recentpost-image:hover::after {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.widget-recentpost-content {
  margin-top: -4px;
}

.widget-recentpost-content h6 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 0;
}

.widget-recentpost-content h6 a {
  color: #3c424f;
}

.widget-recentpost-content h6 a:hover {
  color: #ff6c8d;
}

.widget-recentpost-content span {
  font-size: 14px;
  color: #686b77;
}

/* Widget Categories */
.widget-archives ul li {
  border-bottom: 1px solid #eeeeee;
  padding: 12px 0;
}

.widget-archives ul li:first-child {
  padding-top: 0;
}

.widget-archives ul li a {
  display: block;
  font-weight: 400;
  color: #686b77;
}

.widget-archives ul li a::after {
  content: "";
  clear: both;
  display: table;
}

.widget-archives ul li a span {
  float: right;
}

.widget-archives ul li a span::before {
  content: "(";
}

.widget-archives ul li a span::after {
  content: ")";
}

.widget-archives ul li a:hover {
  color: #ff6c8d;
}

/* Widget Tags */
.widget-tags ul {
  margin-left: -10px;
  margin-top: -10px;
  font-size: 0;
}

.widget-tags ul li {
  list-style: none;
  display: inline-block;
  margin-left: 10px;
  margin-top: 10px;
  font-size: 16px;
}

.widget-tags ul li a {
  display: inline-block;
  vertical-align: middle;
  border: 0;
  background: #ffffff;
  padding: 6px 22px;
  color: #686b77;
}

.widget-tags ul li a:hover {
  background: #ff6c8d;
  color: #ffffff;
}

/* Widget Info */
.widget-info-logo {
  display: inline-block;
  margin-bottom: 20px;
}

.widget-info p {
  font-size: 14px;
  line-height: 24px;
}

.widget-info ul {
  margin-top: 20px;
  font-size: 0;
}

.widget-info ul li {
  display: inline-block;
  font-size: 18px;
}

.widget-info ul li:not(:last-child) {
  margin-right: 10px;
}

.widget-info ul li a {
  display: inline-block;
  height: 35px;
  width: 35px;
  border: 1px solid #a3a3a3;
  text-align: center;
  font-size: 14px;
  border-radius: 100px;
  color: #686b77;
}

.widget-info ul li a i {
  line-height: 33px;
}

.widget-info ul li a:hover {
  background: #ff6c8d;
  border-color: #ff6c8d;
  color: #ffffff;
}

/* Widget Quicklinks */
.widget-quicklinks ul li {
  padding-top: 5px;
}

.widget-quicklinks ul li:first-child {
  padding-top: 0;
}

.widget-quicklinks ul li a {
  display: block;
  font-weight: 400;
  color: #686b77;
}

.widget-quicklinks ul li a::after {
  content: "";
  clear: both;
  display: table;
}

.widget-quicklinks ul li a:before {
  content: "\e661";
  font-family: 'Themify';
  padding-right: 15px;
  font-size: 9px;
}

.widget-quicklinks ul li a span {
  float: right;
}

.widget-quicklinks ul li a span::before {
  content: "(";
}

.widget-quicklinks ul li a span::after {
  content: ")";
}

.widget-quicklinks ul li a:hover {
  color: #ff6c8d;
}

/* Widget Hours */
.widget-hours p {
  font-size: 14px;
  line-height: 24px;
}

.widget-hours ul {
  font-size: 14px;
  line-height: 24px;
}

.widget-hours ul li:not(:last-child) {
  margin-bottom: 6px;
}

.widget-hours ul li span {
  display: inline-block;
  min-width: 70px;
  font-weight: 600;
  margin-right: 5px;
}

/* Widget Recentpost 2 */
.widget-recentpost-2 ul li:not(:last-child) {
  margin-bottom: 12px;
}

.widget-recentpost-2 ul li h6 {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin-bottom: 5px;
}

.widget-recentpost-2 ul li h6 a {
  color: #3c424f;
}

.widget-recentpost-2 ul li h6 a:hover {
  color: #ff6c8d;
}

.widget-recentpost-2 ul li span {
  font-size: 14px;
}

.widget-recentpost-2 ul li span i {
  margin-right: 5px;
}

/* Widget Price Filter */
.widget-pricefilter-actions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 25px;
}

.widget-pricefilter-price {
  font-weight: 600;
  font-family: "PT Serif", serif;
  line-height: 30px;
  margin-bottom: 0;
}

.widget-pricefilter-price span {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.widget-pricefilter-button {
  display: inline-block;
  color: #3c424f;
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  height: 35px;
  line-height: 33px;
  padding: 0 30px;
  background: #686b77;
  border: 1px solid #686b77;
  color: #ffffff;
  border-radius: 4px;
}

.widget-pricefilter-button:hover {
  background: #ff6c8d;
  border-color: #ff6c8d;
  color: #ffffff;
}

.widget-pricefilter-button:focus {
  outline: none;
  background: #ff6c8d;
  border-color: #ff6c8d;
}

.widget-pricefilter .tm-rangeslider {
  background: #e4dfdf;
  height: 6px;
  width: 100%;
  position: relative;
  margin: 5px 0;
  border-radius: 100px;
}

.widget-pricefilter .tm-rangeslider-bar {
  background: #969696;
  height: 7px;
  position: absolute;
}

.widget-pricefilter .tm-rangeslider-leftgrip, .widget-pricefilter .tm-rangeslider-rightgrip {
  height: 15px;
  background: #ff6c8d;
  width: 15px;
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -7.5px;
  border-radius: 100px;
}

/* Widget Popular Product */
.widget-popularproduct ul li {
  display: flex;
  align-items: center;
  padding-top: 25px;
}

.widget-popularproduct ul li:first-child {
  padding-top: 0;
}

.widget-popularproduct-image {
  flex: 0 0 70px;
  max-width: 70px;
  margin-right: 20px;
  position: relative;
  align-self: flex-start;
  border: 1px solid #ebebeb;
}

.widget-popularproduct-image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease-in-out;
}

.widget-popularproduct-image::after {
  content: "\e62d";
  font-family: "Themify";
  position: absolute;
  left: 50%;
  top: 50%;
  color: #ffffff;
  font-size: 18px;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  margin-top: 15px;
}

.widget-popularproduct-image:hover::before {
  opacity: 1;
  visibility: visible;
}

.widget-popularproduct-image:hover::after {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.widget-popularproduct-content {
  margin-top: -4px;
}

.widget-popularproduct-content h6 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 0;
}

.widget-popularproduct-content h6 a {
  color: #3c424f;
}

.widget-popularproduct-content h6 a:hover {
  color: #ff6c8d;
}

.widget-popularproduct-content span {
  font-size: 14px;
  color: #686b77;
}

.widgets-sidebar {
  background: #fdf4f3;
  margin-left: 20px;
}

.widgets-sidebar-left {
  margin-left: 0;
  margin-right: 20px;
}

@media only screen and (max-width: 1199px) {
  .widgets-sidebar {
    margin-left: 0;
  }
  .widgets-sidebar-left {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .widgets-sidebar {
    margin-top: 50px;
  }
  .widgets-sidebar-left {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .widgets-sidebar {
    margin-top: 50px;
  }
  .widgets-sidebar-left {
    margin-left: 0;
    margin-right: 0;
  }
}

/**
 * Comment
 */
.tm-comment {
  display: flex;
  margin-bottom: 30px;
}

.tm-comment:last-child {
  margin-bottom: 0;
}

.tm-comment-thumb {
  flex: 0 0 80px;
  margin-right: 25px;
  border-radius: 3px;
  overflow: hidden;
  align-self: flex-start;
}

.tm-comment-thumb img {
  max-width: 100%;
}

.tm-comment-content::after {
  content: "";
  clear: both;
  display: table;
}

.tm-comment-content h6 {
  margin-top: -5px;
  margin-bottom: 0;
}

.tm-comment-content h6 a {
  color: #3c424f;
}

.tm-comment-content h6 a:hover {
  color: #ff6c8d;
}

.tm-comment-content .tm-ratingbox {
  float: right;
}

.tm-comment-content p {
  margin-top: 8px;
  margin-bottom: 0;
}

.tm-comment-date {
  color: #757575;
}

.tm-comment-replybutton {
  color: #686b77;
  display: inline-block;
  margin-top: 10px;
  font-weight: 600;
}

.tm-comment-replybutton i {
  font-size: 16px;
}

.tm-comment-replybutton:hover {
  color: #ff6c8d;
}

.tm-comment-replypost {
  padding-left: 105px;
}

@media only screen and (max-width: 767px) {
  .tm-comment-replypost {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .tm-comment {
    flex-wrap: wrap;
  }
  .tm-comment-thumb {
    flex: 0 0 60px;
    max-width: 60px;
    margin-right: 20px;
  }
  .tm-comment-content {
    margin-top: 20px;
  }
  .tm-comment-content .tm-ratingbox {
    float: none;
    margin-top: 5px;
  }
  .tm-comment-date {
    display: block;
  }
}

/**
 * Footer
 */
.tm-footer-toparea {
  background-position: top center;
}

.tm-footer-bottomarea {
  padding: 20px 0;
  border-top: 1px solid #ebebeb;
}

.tm-footer-copyright {
  margin-bottom: 0;
  font-size: 14px;
  margin: 5px 0;
}

.tm-footer-copyright a {
  font-weight: 600;
}

.tm-footer-copyright a:hover {
  color: #ff6c8d;
}

.tm-footer-payment {
  text-align: right;
  margin: 5px 0;
}

@media only screen and (max-width: 767px) {
  .tm-footer-copyright {
    text-align: center;
  }
  .tm-footer-payment {
    text-align: center;
  }
}

.widgets-footer {
  margin-top: -50px;
}

.widgets-footer .single-widget {
  margin-top: 50px;
  padding: 0;
}

.widgets-footer .single-widget .widget-title {
  font-weight: 700;
  position: relative;
  padding-bottom: 13px;
  margin-top: -5px;
  margin-bottom: 30px;
}

.widgets-footer .single-widget .widget-title::after {
  width: 100%;
  background: #ebebeb;
}

/**
 * Product Details
 */
.tm-prodetails-images {
  width: 100%;
}

.tm-prodetails-largeimages {
  overflow: hidden;
}

.tm-prodetails-largeimage a {
  position: relative;
  z-index: 1;
  display: block;
}

.tm-prodetails-largeimage a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
}

.tm-prodetails-largeimage a::after {
  content: "\e611";
  font-family: 'Themify';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 110px;
  z-index: 1;
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
  z-index: 3;
}

.tm-prodetails-largeimage a:hover::before {
  visibility: visible;
  opacity: 1;
}

.tm-prodetails-largeimage a:hover::after {
  visibility: visible;
  opacity: 1;
}

.tm-prodetails-thumbnails {
  overflow: hidden;
}

.tm-prodetails-thumbnails .slick-list {
  margin-right: -5px;
  margin-left: -5px;
}

.tm-prodetails-thumbnails .tm-slider-arrows-prev,
.tm-prodetails-thumbnails .tm-slider-arrows-next {
  background: #ff6c8d;
  border-color: #ff6c8d;
  color: #ffffff;
}

.tm-prodetails-thumbnails .tm-slider-arrows-prev {
  left: 0;
  right: auto;
}

.tm-prodetails-thumbnails .tm-slider-arrows-next {
  right: 0;
  left: auto;
}

.tm-prodetails-thumbnail {
  margin: 0 4px;
  margin-top: 9px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s ease-in-out 0s;
  overflow: hidden;
}

.tm-prodetails-thumbnail.slick-current {
  border-color: #ff6c8d;
}

.tm-prodetails-title {
  margin-top: -5px;
  margin-bottom: 5px;
}

.tm-prodetails-title a {
  color: #3c424f;
}

.tm-prodetails-title a:hover {
  color: #ff6c8d;
}

.tm-prodetails-availability {
  margin-top: 5px;
}

.tm-prodetails-availability span {
  color: #ff6c8d;
}

.tm-prodetails-price {
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  color: #3c424f;
}

.tm-prodetails-price del {
  font-size: 75%;
  color: #7a7a7a;
  font-weight: 700;
}

.tm-prodetails-quantitycart {
  margin-top: 30px;
  margin-bottom: 30px;
}

.tm-prodetails-quantitycart .tm-quantitybox {
  margin-right: 20px;
}

.tm-prodetails-color, .tm-prodetails-size, .tm-prodetails-categories, .tm-prodetails-tags, .tm-prodetails-share {
  border-top: 1px dashed #ebebeb;
  padding: 12px 0;
}

.tm-prodetails-color > h6, .tm-prodetails-size > h6, .tm-prodetails-categories > h6, .tm-prodetails-tags > h6, .tm-prodetails-share > h6 {
  margin-bottom: 0;
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}

.tm-prodetails-color ul, .tm-prodetails-size ul, .tm-prodetails-categories ul, .tm-prodetails-tags ul, .tm-prodetails-share ul {
  display: inline-block;
  padding-left: 0;
  margin-bottom: 0;
}

.tm-prodetails-color ul li, .tm-prodetails-size ul li, .tm-prodetails-categories ul li, .tm-prodetails-tags ul li, .tm-prodetails-share ul li {
  list-style: none;
  display: inline-block;
}

.tm-prodetails-color ul li a, .tm-prodetails-size ul li a, .tm-prodetails-categories ul li a, .tm-prodetails-tags ul li a, .tm-prodetails-share ul li a {
  display: inline-block;
  vertical-align: middle;
  color: #686b77;
}

.tm-prodetails-color ul li a:hover, .tm-prodetails-size ul li a:hover, .tm-prodetails-categories ul li a:hover, .tm-prodetails-tags ul li a:hover, .tm-prodetails-share ul li a:hover {
  color: #ff6c8d;
}

.tm-prodetails-color ul {
  font-size: 0;
  line-height: 1;
  vertical-align: middle;
}

.tm-prodetails-color ul li:not(:last-child) {
  margin-right: 10px;
}

.tm-prodetails-color ul li span {
  height: 16px;
  width: 16px;
  line-height: 16px;
  text-align: center;
  display: inline-block;
  border-radius: 100px;
  vertical-align: middle;
  font-size: 14px;
  border: 2px solid transparent;
}

.tm-prodetails-color ul li.is-checked span {
  border-color: #ff6c8d;
}

.tm-prodetails-color ul li.red span {
  background: red;
}

.tm-prodetails-color ul li.green span {
  background: green;
}

.tm-prodetails-color ul li.blue span {
  background: blue;
}

.tm-prodetails-color ul li.black span {
  background: black;
}

.tm-prodetails-size ul li span {
  cursor: pointer;
}

.tm-prodetails-size ul li.is-checked span {
  text-decoration: underline;
}

.tm-prodetails-categories ul li::after, .tm-prodetails-tags ul li::after {
  content: ",";
}

.tm-prodetails-categories ul li:last-child::after, .tm-prodetails-tags ul li:last-child::after {
  display: none;
}

.tm-prodetails-share ul li {
  margin-right: 10px;
}

.tm-prodetails-share ul li a {
  font-size: 18px;
}

.tm-prodetails-description ul {
  padding-left: 0;
  margin-bottom: 0;
}

.tm-prodetails-description ul li {
  list-style: none;
}

.tm-prodetails-review > h5 {
  margin-bottom: 30px;
}

.tm-prodetails-review .tm-rating {
  line-height: 1;
  vertical-align: middle;
}

@media only screen and (max-width: 1199px) {
  .tm-prodetails-thumbnails .tm-slider-arrows-prev,
  .tm-prodetails-thumbnails .tm-slider-arrows-next {
    top: 50%;
    margin-top: -20px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-prodetails-content {
    margin-top: 40px;
  }
}

.tm-similliar-products h4 {
  margin-bottom: 25px;
}

/* Quantitybox */
.tm-quantitybox {
  position: relative;
  display: inline-block;
  width: 90px;
}

.tm-quantitybox input {
  padding-left: 5px;
  padding-right: 30px;
  text-align: center;
}

.tm-quantitybox input:focus {
  border-color: #ebebeb;
}

.tm-quantitybox-button {
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid #ebebeb;
  height: 25.5px;
  width: 25px;
  line-height: 23px;
  text-align: center;
  cursor: pointer;
}

.tm-quantitybox-button.increment-button {
  top: 0;
}

.tm-quantitybox-button.decrement-button {
  top: auto;
  bottom: 0;
}

@media only screen and (max-width: 767px) {
  .tm-quantitybox-button {
    height: 22.5px;
  }
}

/* Quickview */
.tm-product-quickview {
  padding: 15px 0;
  display: none;
}

.tm-product-quickview.fancybox-content {
  background: transparent;
}

.tm-product-quickview .fancybox-close-small {
  padding: 0;
  right: 165px;
  top: 15px;
  height: 30px;
  width: 30px;
}

.tm-product-quickview .fancybox-close-small svg path {
  fill: #3c424f;
}

.tm-product-quickview-inner {
  background: #ffffff;
  padding: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tm-product-quickview .fancybox-close-small {
    right: 95px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-product-quickview .fancybox-close-small {
    right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-product-quickview .fancybox-close-small {
    right: 15px;
  }
}

/**
 * Shopping Cart
 */
.tm-cart-table,
.tm-wishlist-table {
  text-align: center;
}

.tm-cart-table tr th,
.tm-wishlist-table tr th {
  text-transform: uppercase;
  color: #3c424f;
  font-weight: 700;
}

.tm-cart-table tr td,
.tm-wishlist-table tr td {
  vertical-align: middle;
}

.tm-cart-table .tm-cart-col-image,
.tm-cart-table .tm-wishlist-col-image,
.tm-wishlist-table .tm-cart-col-image,
.tm-wishlist-table .tm-wishlist-col-image {
  width: 180px;
  min-width: 180px;
}

.tm-cart-table .tm-cart-col-remove,
.tm-cart-table .tm-wishlist-col-remove,
.tm-wishlist-table .tm-cart-col-remove,
.tm-wishlist-table .tm-wishlist-col-remove {
  width: 100px;
  min-width: 100px;
}

.tm-cart-table .tm-cart-col-productname,
.tm-cart-table .tm-wishlist-col-productname,
.tm-wishlist-table .tm-cart-col-productname,
.tm-wishlist-table .tm-wishlist-col-productname {
  min-width: 250px;
}

.tm-cart-table .tm-cart-col-price, .tm-cart-table .tm-cart-col-total,
.tm-cart-table .tm-wishlist-col-price,
.tm-cart-table .tm-wishlist-col-total,
.tm-wishlist-table .tm-cart-col-price,
.tm-wishlist-table .tm-cart-col-total,
.tm-wishlist-table .tm-wishlist-col-price,
.tm-wishlist-table .tm-wishlist-col-total {
  min-width: 100px;
}

.tm-cart-table .tm-cart-col-addcart,
.tm-cart-table .tm-wishlist-col-addcart,
.tm-wishlist-table .tm-cart-col-addcart,
.tm-wishlist-table .tm-wishlist-col-addcart {
  min-width: 100px;
}

.tm-cart-table .tm-cart-productimage,
.tm-cart-table .tm-wishlist-productimage,
.tm-wishlist-table .tm-cart-productimage,
.tm-wishlist-table .tm-wishlist-productimage {
  margin: 5px 30px;
  display: inline-block;
  border: 1px solid #ebebeb;
}

.tm-cart-table .tm-cart-productname,
.tm-cart-table .tm-wishlist-productname,
.tm-wishlist-table .tm-cart-productname,
.tm-wishlist-table .tm-wishlist-productname {
  color: #3c424f;
  font-weight: 600;
}

.tm-cart-table .tm-cart-productname:hover,
.tm-cart-table .tm-wishlist-productname:hover,
.tm-wishlist-table .tm-cart-productname:hover,
.tm-wishlist-table .tm-wishlist-productname:hover {
  color: #ff6c8d;
}

.tm-cart-table .tm-cart-totalprice,
.tm-cart-table .tm-wishlist-totalprice,
.tm-wishlist-table .tm-cart-totalprice,
.tm-wishlist-table .tm-wishlist-totalprice {
  font-weight: 700;
  color: #686b77;
}

.tm-cart-table .tm-cart-removeproduct,
.tm-cart-table .tm-wishlist-removeproduct,
.tm-wishlist-table .tm-cart-removeproduct,
.tm-wishlist-table .tm-wishlist-removeproduct {
  border: none;
}

.tm-cart-table .tm-cart-removeproduct:hover,
.tm-cart-table .tm-wishlist-removeproduct:hover,
.tm-wishlist-table .tm-cart-removeproduct:hover,
.tm-wishlist-table .tm-wishlist-removeproduct:hover {
  color: #ff6c8d;
}

.tm-cart-table .tm-cart-removeproduct:focus,
.tm-cart-table .tm-wishlist-removeproduct:focus,
.tm-wishlist-table .tm-cart-removeproduct:focus,
.tm-wishlist-table .tm-wishlist-removeproduct:focus {
  outline: none;
}

@media only screen and (max-width: 767px) {
  .tm-cart-table .tm-cart-col-image,
  .tm-cart-table .tm-wishlist-col-image,
  .tm-wishlist-table .tm-cart-col-image,
  .tm-wishlist-table .tm-wishlist-col-image {
    width: 120px;
    min-width: 120px;
  }
  .tm-cart-table .tm-cart-productimage,
  .tm-cart-table .tm-wishlist-productimage,
  .tm-wishlist-table .tm-cart-productimage,
  .tm-wishlist-table .tm-wishlist-productimage {
    margin: 0;
  }
}

.tm-cart-bottomarea {
  margin-top: 30px;
}

.tm-cart-coupon {
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
}

.tm-cart-coupon label {
  flex: 0 0 100%;
  max-width: 100%;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
}

.tm-cart-coupon input:not([type="submit"]) {
  margin-right: 10px;
  max-width: 220px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.tm-cart-pricebox {
  text-align: right;
}

.tm-cart-pricebox > h2 {
  margin-top: -8px;
  font-weight: 700;
}

.tm-cart-pricebox table {
  margin-bottom: 30px;
}

.tm-cart-pricebox table td {
  padding: 0;
  font-size: 16px;
  color: #686b77;
}

.tm-cart-pricebox table .tm-cart-pricebox-shipping td {
  padding-top: 5px;
}

.tm-cart-pricebox table .tm-cart-pricebox-total td {
  color: #3c424f;
  font-weight: 700;
  font-size: 22px;
  padding-top: 20px;
}

@media only screen and (max-width: 767px) {
  .tm-cart-pricebox {
    text-align: left;
    margin-top: 30px;
  }
}

/**
 * Checkout
 */
.tm-checkout-coupon {
  margin-bottom: 50px;
}

.tm-checkout-coupon > a {
  display: block;
  background: transparent;
  padding: 13px 20px;
  font-size: 14px;
  color: #3c424f;
  border: 5px solid transparent;
  border-left: 5px solid #ff6c8d;
  box-shadow: 0px 3px 6px 2px rgba(0, 0, 0, 0.1);
}

.tm-checkout-coupon > a span {
  color: #ff6c8d;
  font-weight: 600;
}

.tm-checkout-couponform {
  padding-top: 30px;
  display: flex;
}

.tm-checkout-couponform input:not([type="submit"]) {
  max-width: 300px;
  flex-shrink: 100;
  border-radius: 4px;
}

.tm-checkout-form .small-title {
  font-weight: 700;
  text-transform: uppercase;
}

.tm-checkout-differentform {
  display: none;
}

.tm-checkout-differentform .tm-form-inner {
  margin-top: 0;
}

.tm-checkout-orderinfo {
  background: #fdf4f3;
  padding: 30px;
}

.tm-checkout-ordertable td,
.tm-checkout-ordertable th {
  padding: 0;
}

.tm-checkout-ordertable thead th {
  text-transform: uppercase;
  font-size: 16px;
  padding-bottom: 10px;
}

.tm-checkout-ordertable thead th + th {
  text-align: right;
}

.tm-checkout-ordertable tbody {
  border-top: 2px solid #ebebeb;
}

.tm-checkout-ordertable tbody tr {
  border-bottom: 1px solid #ebebeb;
}

.tm-checkout-ordertable tbody td {
  padding: 10px 0;
}

.tm-checkout-ordertable tbody td + td {
  text-align: right;
}

.tm-checkout-ordertable tfoot tr:first-child td {
  padding-top: 25px;
}

.tm-checkout-ordertable tfoot tr td + td {
  text-align: right;
}

.tm-checkout-subtotal td, .tm-checkout-shipping td {
  font-size: 16px;
  padding: 5px 0;
}

.tm-checkout-subtotal td + td, .tm-checkout-shipping td + td {
  color: #3c424f;
  font-weight: 700;
}

.tm-checkout-total td {
  padding-top: 15px;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
}

.tm-checkout-total td + td {
  color: #ff6c8d;
}

.tm-checkout-payment {
  margin-top: 20px;
}

.tm-checkout-payment label {
  margin-bottom: 0;
  display: block;
  color: #3c424f;
  font-weight: 700;
}

.tm-checkout-payment-content {
  display: none;
  padding-left: 25px;
  padding-top: 10px;
}

.tm-checkout-payment-content p {
  margin-bottom: 0;
}

.tm-checkout-submit {
  margin-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-checkout-orderinfo {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-checkout-orderinfo {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .tm-checkout-orderinfo {
    padding: 20px;
  }
}

/**
 * My Account
 */
.tm-myaccount-dashboard, .tm-myaccount-address {
  border: 1px solid #ebebeb;
  padding: 30px;
}

.tm-myaccount-dashboard p a {
  color: #ff6c8d;
}

.tm-myaccount-dashboard p a:hover {
  color: #ff6c8d;
}

.tm-myaccount-orders table {
  text-align: center;
}

.tm-myaccount-orders table th,
.tm-myaccount-orders table td {
  vertical-align: middle;
}

.tm-myaccount-orders-col-id {
  min-width: 100px;
}

.tm-myaccount-orders-col-date {
  min-width: 170px;
}

.tm-myaccount-orders-col-status {
  min-width: 100px;
}

.tm-myaccount-orders-col-total {
  min-width: 180px;
}

.tm-myaccount-orders-col-view {
  min-width: 120px;
}

.tm-myaccount-address > p {
  margin-bottom: 25px;
}

.tm-myaccount-address-billing, .tm-myaccount-address-shipping {
  border: 1px solid #ebebeb;
  padding: 30px;
}

.tm-myaccount-address-billing::after, .tm-myaccount-address-shipping::after {
  content: "";
  clear: both;
  display: table;
}

.tm-myaccount-address-billing h3, .tm-myaccount-address-shipping h3 {
  font-weight: 700;
}

.tm-myaccount-address-billing address, .tm-myaccount-address-shipping address {
  margin-bottom: 0;
}

.tm-myaccount-address-billing a.edit-button, .tm-myaccount-address-shipping a.edit-button {
  float: right;
  padding: 0 8px;
  background: #ff6c8d;
  color: #ffffff;
  border-radius: 3px;
  margin-top: 5px;
}

@media only screen and (max-width: 575px) {
  .tm-myaccount-dashboard, .tm-myaccount-address {
    padding: 20px;
  }
  .tm-myaccount-address-billing, .tm-myaccount-address-shipping {
    padding: 20px;
  }
}

.tm-login-form h4,
.tm-register-form h4 {
  margin-top: -7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tm-register-form {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .tm-register-form {
    margin-top: 50px;
  }
}
