/**
 * Call to action
 */

.tm-calltoaction {
    &-image {
        text-align: center;
    }

    &-content {
        text-align: center;
        padding: 100px 0;

        h3 {
            margin-top: -8px;
        }

        h4 {

            span {
                color: #686b77;
            }

            a {
                color: $color-theme;
            }
        }
    }

    @media #{$layout-tablet} {
        &-image {
            margin-top: 80px;
        }

        &-content {
            padding-top: 40px;
            padding-bottom: 80px;
        }
    }

    @media #{$layout-mobile-lg} {
        &-image {
            margin-top: 70px;
        }

        &-content {
            padding-top: 40px;
            padding-bottom: 70px;
        }
    }
}